import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { customAPI } from '../services/api';

const getReport = (token, id, type) =>
  customAPI(token)
    .get(`api/Reports/${id}/${type.toLowerCase() === "pbi" ? "EmbedInfo" : "DomoEmbedInfo"}`)
    .then((res) => res?.data);

export const useReportAPI = ({ id, type ,enable}) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery([`GET-REPORT-${id}`,id], () =>
    getReport(token, id, type),
    {
      enabled: !enable,
    }
  );

  return {
    data,
    isLoading,
    refetch,
    emebedDetails: data
  };
};
