import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../services/api";

const ManagePaymentSuccess = () => {
  const [paymentStatusObj, setPaymentStatusObj] = useState({});
  const { token } = useSelector((store) => store.auth);
  const getPaymentInfo = async () => {
    fetch(
      `${BASE_URL}/api/Reports/InvPayment/SessionDetails?sessionId=${localStorage.getItem("SessionId")}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        localStorage.removeItem('InvoicePayInfo');
        setPaymentStatusObj(data);
      });
  };

  useEffect(() => {
    
      getPaymentInfo();
    
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignContent: "center" }}>
        <h5 style={{ marginLeft: 8 }}>Payment Details</h5>
      </div>
      <div style={{ marginTop: 50, marginLeft: "10%", height: 100 }}>
        <h6>
          {paymentStatusObj.paymentStatus?.toLowerCase() == "paid"
            ? `Invoice payment of amount - ${paymentStatusObj.paidAmout} has been paid successfully.
            Please close this window and navigate to main page`
            : ""}
        </h6>
      </div>
    </>
  );
};

export default ManagePaymentSuccess;
