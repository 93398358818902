import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Table } from "../Content/InvoiceTable";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ManageInvoicesComp = ({
  handleChangeStatus,
  handleToggle,
  setPage,
  list,
  page,
  isLoadingList,
  changedCount,
  control,
  controls,
  actionDisabled,
  handleChangeValue,
  handleDeleteUsers,
  isModalOpen,
  showModal,
  hideModal,
  sort,
  setSort,
  handlecChangeDropdown,
  onSearch,
  clear,
  deleteMutation,
  onEditClick,
  totalDispute,
  totalLineItems,
  undisputedBalance,
  balance,
  isLoading,
  onDownLoadClick,
  ccy,
  disputeObj,status
}) => {
  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0 || status=="Outstanding Only" ||  status=="Disputed Only";

  const isPrevButtonDisabled = page === 1 || status=="Outstanding Only"|| status=="Disputed Only";

  const handleBack = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Table
              items={list ?? []}
              totalDispute={totalDispute}
              totalLineItems={totalLineItems}
              undisputedBalance={undisputedBalance}
              balance={balance}
              ccy={ccy}
              isLoadingList={isLoadingList}
              changedCount={changedCount}
              handleChangeValue={handleChangeValue}
              handleOpenModal={handleToggle}
              actionDisabled={actionDisabled}
              handleDeleteUsers={handleDeleteUsers}
              isOnboarding={false}
              handleChangeStatus={handleChangeStatus}
              isModalOpen={isModalOpen}
              showModal={() => showModal()}
              hideModal={() => hideModal()}
              control={control}
              controls={controls}
              handleReset={clear}
              handlecChangeDropdown={handlecChangeDropdown}
              handleBack={handleBack}
              handleNext={handleNext}
              isNextButtonDisabled={isNextButtonDisabled}
              isNextPrevDisabled={isPrevButtonDisabled}
              sort={sort}
              onSearch={onSearch}
              setSort={setSort}
              deleteMutation={deleteMutation}
              onEditClick={onEditClick}
              onDownLoadClick={onDownLoadClick}
              isLoading={isLoading}
              disputeObj={disputeObj}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
