import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import { getTheme, mergeStyleSets, FontWeights, Modal } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { BASE_URL } from "../../services/api";
const PaymentModel = ({ isModalOpen, hideModal, record, token }) => {
  const stripePromise = loadStripe(
    "pk_test_51Ppn9D06RIiEcWG3C37xvy70ZNZhfJXNOeALlCUl5MjJ120wiOOPfXZF2tdagz9NQWZcG8dhACRYcvpKCYVLxmqH00oCfuFJJm"
  );
  const fetchClientSecret = React.useCallback(() => {
    return fetch(`${BASE_URL}/api/Reports/${record.id}/CreateCheckoutSession`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);
  const options = {
    fetchClientSecret: fetchClientSecret,
  };
  const titleId = useId("title");
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </Modal>
  );
};

const cancelIcon = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxWidth: "95%",
    minWidth: "75%",
    maxHeight: "90%",
    minHeight: "90%",
  },
  header: [
    {
      flex: "1 1 auto",
      borderTop: `4px solid rgb(79, 82, 145)`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default PaymentModel;
