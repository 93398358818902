import  ManageUsers  from '../components/ManageUsers';
import ManageReports from '../components/ManageReports';
import ManageComapnies from "../components/ManageComapnies";
import ManageGroups from "../components/ManageGroups";
import ManagePaymentSuccess from '../components/ManagePaymentSuccess'
import Home from '../pages/Home';
import Report from '../pages/Report';
import { ProtectedRoute } from "./ProtectedRoute";
import ManagePayment from '../components/ManagePayment';
import Widget from '../pages/Widget';
export const routes = [
  {
    path: "",
    element: <Home />,
  },
  {
    path: "report",
    children: [
      {
        path: ":type/:id",
        element: <Report />,
      },
    ],
  },
  {
    path: "widget",
    children: [
      {
        path: ":type/:id",
        element: <Widget />,
      },
    ],
  },
  {
    path: "/manageusers",
    element: (
      <ProtectedRoute>
        <ManageUsers />
      </ProtectedRoute>
    ),
  },
  {
    path: "/managewidgets",
    element: (
      <ProtectedRoute>
        <ManageReports />
      </ProtectedRoute>
    ),
  },
  {
    path: "/managecompanies",
    element: (
      <ProtectedRoute>
        <ManageComapnies />
      </ProtectedRoute>
    ),
  },
  {
    path: "/managegroups",
    element: (
      <ProtectedRoute>
        <ManageGroups />
      </ProtectedRoute>
    ),
  },
  {
    path: "/paymentsuccess",
    element: (
      <ProtectedRoute>
        <ManagePaymentSuccess />
      </ProtectedRoute>
    ),
  },
  {
    path: "/invoicepayment",
    element: (
      <ProtectedRoute>
        <ManagePayment />
      </ProtectedRoute>
    ),
  },
];
