
import { Controller } from 'react-hook-form';
import { ComboBox, Dropdown } from '@fluentui/react';

export const ControlledDropdown = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ''}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error }
      }) => {
        return (
         !props.isComboBox ? <Dropdown
            onChange={onChange}
            value={value}
            onBlur={onBlur}
            name={fieldName}
            errorMessage={!props?.errorMain?.[props.name]?.ref?.value && error && error.message}
            {...props}
            defaultValue={value}
          />:<ComboBox
          onChange={onChange}
          autoComplete='on'
          allowFreeform
          options={props.options}
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={!props?.errorMain?.[props.name]?.ref?.value && error && error.message}
          {...props}
          defaultValue={value}
        />
        );
      }}
    />
  );
};
