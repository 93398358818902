import React, { useState,useMemo } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
//import { Table } from "../Content/Table";
import Table from './MaterialTable';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ManageReportsComp = ({
  handleToggle,
  setPage,
  list,
  page,
  isLoadingList,
  handleDeleteReports,
  onEditClick,
  setShowFilter
}) => {
  
  // const handlecChangeDropdown = (key, val) => {
  //   setValue(key, val);
  // };

  // const handleReset = () => {
  //   reset();
  //   setValue('type', null);
  //   setValue('category', null);
  // };
  const [rowSelection,setRowSelection]=useState({})
  const reportsList = useMemo(() => {
    return list ?? [];
  }, [list]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "type",
        header: "Type",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "groupName",
        header: "Group",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "companyName",
        header: "Comapny",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "sequence",
        header: "Seq",
        size: 150,
      },
      
    ],
    []
  );

  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0;

  const isPrevButtonDisabled = page === 1;

  const handleBack = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col lg={12}>
          {location.pathname?.includes("managewidgets") &&<Table
        columns={columns}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        handleToggle={handleToggle}
        onEditClick={onEditClick}
        isLoadingList={isLoadingList}
        data={reportsList}
        handleBack={handleBack}
        handleNext={handleNext}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextPrevDisabled={isPrevButtonDisabled}
        handleDelete={handleDeleteReports}
        type={"widgets"}
        showModalFilter={setShowFilter}
      />}
            {/* <Table
              items={list ?? []}
              isLoadingList={isLoadingList}
              changedCount={changedCount}
              handleChangeValue={handleChangeValue}
              handleOpenModal={handleToggle}
              actionDisabled={actionDisabled}
              isOnboarding={false}
              handleChangeStatus={handleChangeStatus}
              isModalOpen={isModalOpen}
              showModal={() => showModal()}
              hideModal={() => hideModal()}
              control={control}
              controls={controls}
              handleReset={clear}
              handlecChangeDropdown={handlecChangeDropdown}
              handleBack={handleBack}
              handleNext={handleNext}
              refetchList={handleCreate}
              isNextButtonDisabled={isNextButtonDisabled}
              isNextPrevDisabled={isPrevButtonDisabled}
              sort={sort}
              onSearch={onSearch}
              setSort={setSort}
              handleDeleteReports={handleDeleteReports}
              type
              onEditClick={onEditClick}
            /> */}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
