import React,{useEffect, useMemo, useState} from 'react';
import { NavLink } from 'react-bootstrap';
import styled from 'styled-components';
import { Nav } from '@fluentui/react/lib/Nav';
import { useLocation, useNavigate } from 'react-router';
import { SearchBox } from "@fluentui/react";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from '../redux/userSlice'
import Loader from './Loader';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  transition: 0.6s ease-in-out all;
  min-width: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  max-width: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  box-shadow: #bdbdbd 1px -7px 14px 0px;
  height: calc(100vh - 64px);
  position: fixed;
  background-color: white;
  z-index: 12;
  top: 56px;
  left: 0;
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  margin:0px 0px 0px 4px;
  
  border: 1px solid black;
  border-radius: 2px;

  .ms-CommandBar {
    padding: 0;
  }
`;
const searchBoxStyles = {
  root: {
    width: "100%",
    height: 40,
    padding: 10,
    border: "none",
    outline: "none",
    "&:hover, &::after": {
      border: "none",
      outline: "none",
    },
  },
};
const Label = styled.h5`
  margin: 10px;
`;

const ListWrapper = styled.ul`
  position: relative;
  height: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0;
`;

const ReportList = styled.ul`
  margin: 0;
  padding: 0 0 0 15px;
`;

const ReportItem = styled.li`
  display: flex;
`;

const GroupItemStyle = styled.li`
  display: flex;
  flex-direction: column;
`;

const GroupTitle = styled.h5`
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 600;
`;

const ReportTitle = styled.h5`
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 400;
`;


const GroupItem = ({ title, reports }) => {
  return (
    <GroupItemStyle>
      <GroupTitle>{title}</GroupTitle>
      <ReportList>
        {reports?.map((report) => (
          <ReportItem key={report.id}>
            <NavLink href={report.category?.toLowerCase()=="widget"?`/widget/${report.id}`:`/report/${report.id}`}>
              <ReportTitle>{report.name}</ReportTitle>
            </NavLink>
          </ReportItem>
        ))}
      </ReportList>
    </GroupItemStyle>
  );
};

export const SubMenu = ({ isOpen, data,search,setSearch,
isLoading }) => {
    const { currentUser } = useSelector((store) => store.userReducer);
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isClearSelection,setIsClearSelection]=useState(false);

  const splittedLocation = location.pathname.split('/');
  const id = splittedLocation?.[3];
  const getFilterList = () => {
    let newData = data.filter((val) => {
      return val.name.toLowerCase().includes(search.toLowerCase());
    });
    return newData;
  };
  const filterdData = useMemo(() => {
    return data?.length && search.length > 2
      ? getFilterList()
      : search?.length < 3
      ? data
      : [];
  }, [data, search]);
  const handleSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    if (search?.length > 2) {
      let filterdLinks = filterdData
        ?.map((i) => i.links)
        .reduce((acc, current) => {
          current.forEach((item) =>
            item.links.forEach((g) => {
              acc.push(g);
            })
          );
          return acc;
        }, []);
      if (
        (search?.length > 2 && !filterdLinks.map((i) => i.id)?.includes(id)) ||
        (data?.length && !filterdData?.length)
      ) {
        setIsClearSelection(true);
        setTimeout(() => {
          navigate("/");
        }, 50);
      }
    } else {
      setIsClearSelection(false);
    }
  }, [search, filterdData?.length, data?.length]);

  const handleLink = (_, item) => {
    if (item.category && item.id) {
      let companyEle = document.getElementsByClassName("ms-Nav-group");
      for (var i = 0; i <= companyEle?.length - 1; i++) {
        if(companyEle[i]?.firstChild?.style)
        companyEle[i].firstChild.style.backgroundColor = "";
      }
      let element = document.getElementById(item.id);
      if (element) {
        element.closest(".ms-Nav-group").firstChild.style.backgroundColor =
          "#cfe2ff";
            dispatch(
              setCurrentUser({
                ...currentUser,
                companyName: element.closest(".ms-Nav-group").firstChild.textContent.replaceAll('', ''),
              })
            );
      }
      if(item.category?.toLowerCase()=="widget")
        navigate(`/widget/${item.reportType}/${item.id}`)
      else
      navigate(`/report/${item.reportType}/${item.id}`);
    }
  };
  useEffect(() => {
    if (document.getElementsByClassName("ms-Nav-group") && id) {
      let element = document.getElementsByClassName("ms-Nav-group");
      if (element.length > 0) {
        let index = null;
        data
          ?.map((i) => i.links)
          .reduce((acc, current) => {
            current.forEach((item) =>
              item.links.forEach((g) => {
                if (g.id == id) {
                  index = g.comapnyIndex;
                }
              })
            );
          }, []);
        if (element[index]){
          element[index].firstChild.style.backgroundColor = "#cfe2ff";
          element[index].firstChild.title  =element[index].firstChild.textContent.replaceAll('', '');
          if (!Object.prototype.hasOwnProperty.call(currentUser || {},"companyName")) {
            dispatch(
              setCurrentUser({
                ...currentUser,
                companyName: element[index].firstChild.title,
              })
            );
          }
        }
      }
    }
    else{
      let companyEle = document.getElementsByClassName("ms-Nav-group");
      for (var i = 0; i <= companyEle?.length - 1; i++) {
        if(companyEle[i]?.firstChild?.style){
        companyEle[i].firstChild.style.backgroundColor = "";
        companyEle[i].firstChild.title =companyEle[i].firstChild.textContent.replaceAll('', '') ;
        if (!Object.prototype.hasOwnProperty.call(currentUser || {},"companyName")) {
          dispatch(
            setCurrentUser({
              ...currentUser,
              companyName: companyEle[i].firstChild.title,
            })
          );
        }
        }
      }
    }
  }, [data?.length,id]);
  //  const isLoading = isLoadingList || isFetchingList;

  return (
    <>
      <Wrapper isOpen={isOpen}>
        <Label>{data?.[0]?.companyName}</Label>
        <ListWrapper>
          {data?.[0]?.groups?.map((group, g) => (
            <GroupItem
              key={g}
              title={group.groupName}
              reports={group.reports}
            />
          ))}
        </ListWrapper> 
        
          <div style={{marginBottom:(filterdData?.length  ? 2: data?.length?"4px":"90vh"),position:'relative'}}>
            {/* <Label>Companies</Label> */}
            <SearchWrapper>
              <SearchBox
                value={search}
                styles={searchBoxStyles}
                placeholder="Company Filter"
                onChange={(_, newValue) => handleSearch(newValue)}
                onSearch={(newValue) => handleSearch(newValue)}
              />
            </SearchWrapper>
          </div>
          {isLoading && <div className='absolute-spinner-nav'><Loader /></div>}
        <Nav
          styles={{
            groupContent: {
              marginBottom: "16px",
            },
            root: {
              overflowY: "auto",
              height: "90vh",
            },
          }}
          key={isClearSelection}
          selectedKey={id??null}
          ariaLabel="Nav example with nested links"
          groups={
            filterdData?.length || search?.length > 2 ? filterdData : data
          }
          onLinkClick={handleLink}
        />
      </Wrapper>
    </>
  );
};
