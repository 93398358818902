import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { customAPI } from "../services/api";

const getMe = (token) =>
  customAPI(token)
    .get(`api/Me`)
    .then((res) => res?.data);

export const useProfile = ({ token: newToken }) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery([`GET-ME`, newToken], () =>
    getMe(token)
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
