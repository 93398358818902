import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Table } from "../Content/InvoiceTable";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ManageInvoicesLineItemsComp = ({
  handleChangeStatus,
  handleToggle,
  setPage,
  list,
  page,
  isLoadingList,
  changedCount,
  control,
  controls,
  actionDisabled,
  handleChangeValue,
  handleDeleteUsers,
  isModalOpen,
  showModal,
  hideModal,
  sort,
  setSort,
  handlecChangeDropdown,
  onSearch,
  clear,
  deleteMutation,
  onEditClick,
  isUpdateButton,
  copyLineItems,
  updateDisputeNotes,
  dupList,
  onDownLoadClick,
  cmInvoices
}) => {
  const [isShowTextBox, setIsShowTextBox] = useState(false);
  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0;

  const isPrevButtonDisabled = page === 1;

  const handleBack = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };
  const handleShowTextBox = () => {
    setIsShowTextBox(true);
  };

  const handleNext = () => {
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Table
              items={list ? list : []}
              isLoadingList={isLoadingList}
              changedCount={changedCount}
              handleChangeValue={handleChangeValue}
              handleOpenModal={handleToggle}
              actionDisabled={actionDisabled}
              handleDeleteUsers={handleDeleteUsers}
              isOnboarding={false}
              handleChangeStatus={handleChangeStatus}
              isModalOpen={isModalOpen}
              showModal={() => showModal()}
              hideModal={() => hideModal()}
              control={control}
              controls={controls}
              handleReset={clear}
              handlecChangeDropdown={handlecChangeDropdown}
              handleBack={handleBack}
              handleNext={handleNext}
              isNextButtonDisabled={isNextButtonDisabled}
              isNextPrevDisabled={isPrevButtonDisabled}
              sort={sort}
              onSearch={onSearch}
              setSort={setSort}
              deleteMutation={deleteMutation}
              onEditClick={onEditClick}
              type={true}
              isUpdateButton={isUpdateButton}
              handleShowTextBox={handleShowTextBox}
              isShowTextBox={isShowTextBox}
              copyLineItems={copyLineItems}
              updateDisputeNotes={updateDisputeNotes}
              dupList={dupList}
              isLineItems
              onDownLoadClick={onDownLoadClick}
              cmInvoices={cmInvoices}
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
