import React, { useState, useMemo } from "react";
import { useParams } from "react-router";
import CreateCompany from "../CreateCompany/index.jsx";
import { useManageCompaniesAPI } from "../../hooks/useManageCompaniesAPI.js";
import useManageComany from "../../hooks/useManageCompany.js";
import { toast } from "react-toastify";
import Table from "../Content/MaterialTable";
import { BASE_URL } from "../../services/api.js";
import { useSelector } from "react-redux";

const SEARCH_DATA = {
name:""
};

const ManageComapnies = () => {
  const { id } = useParams();
  const [companyRecord, setCompanyRecord] = React.useState({});
  const { token } = useSelector((store) => store.auth);
  const [rowSelection, setRowSelection] = useState([]);
  const [page, setPage] = React.useState(1);
  const [sort] = React.useState([]);
  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const {
    data: list,
    isLoadingList,
    refetchList,
    handleDeleteCompanies,
  } = useManageCompaniesAPI({
    id,
    page,
    enabled: true,
    sort,
    ...searchData
  });
  const { createMutation, updateMutation } = useManageComany({
    id: companyRecord.id,
  });

  const [openModal, setOpenModal] = React.useState(false);
  
  const handleToggle = (isCreate) => {
    if (isCreate) setCompanyRecord({ ...{} });
    setOpenModal((prev) => !prev);
  };

  const onEditClick = (record) => {
    setCompanyRecord({ ...record.original });
    setOpenModal(true);
  };
  const handleCreate = () => {
    refetchList();
    toast.success(
      companyRecord.id
        ? "Company updated successfully"
        : "Company created successfully"
    );
  };

  const companyList = useMemo(() => {
    return list ?? [];
  }, [list]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "companyName",
        header: "Company Name",
        size: 150,
      },
    ],
    []
  );
  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0;

  const isPrevButtonDisabled = page === 1;

  const handleBack = () => {
    setRowSelection({})
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setRowSelection({})
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };
  const onSyncClick = () => {
    fetch(`${BASE_URL}/api/Company/RefreshSync`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      method: "POST",
    })
      .then(() => {
        toast.success("Sync started successfully");
      })
      .catch(() => {});
  };
  return (
    <div>
      <div style={{ display: "flex", alignContent: "center" }}>
        <h5 style={{ marginLeft: 8 }}>Companies</h5>
      </div>
      {openModal && (
        <CreateCompany
          key={id}
          open={openModal}
          handleClose={handleToggle}
          createMutation={createMutation}
          updateMutation={updateMutation}
          onSuccess={handleCreate}
          userId={id}
          companyRecord={companyRecord}
        />
      )}
      {location.pathname?.includes("managecompanies") &&<Table
        columns={columns}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        handleToggle={handleToggle}
        onEditClick={onEditClick}
        isLoadingList={isLoadingList}
        data={companyList}
        handleBack={handleBack}
        handleNext={handleNext}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextPrevDisabled={isPrevButtonDisabled}
        handleDelete={handleDeleteCompanies}
        type={"companies"}
        setSearchData={setSearchData}
        hideRowActions
        onSyncClick={onSyncClick}
      />}
    </div>
  );
};

export default ManageComapnies;
