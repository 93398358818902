import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { customAPI } from "../services/api";

const createRFeedback = (data, token) => {
  return customAPI(token).post("api/PostFeedback", data);
};

const useFeedback = () => {
  const { token } = useSelector((store) => store.auth);

  const createMutation = useMutation((data) => createRFeedback(data, token), {
    onError: (error) => {
      if (error.response.status == 409) {
        error.message = "Duplicate Feedback entry exists";
        return error;
      } else {
        error.message = "Something went wrong";
        return error;
      }
    },
  });
  return {
    createMutation,
  };
};

export default useFeedback;
