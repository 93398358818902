import React, { useState ,useMemo} from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Table from "./MaterialTable";
//import { Table } from "../Content/Table";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ManageUsersComp = ({
  handleToggle,
  setPage,
  list,
  page,
  isLoadingList,
  handleDeleteUsers,
  onEditClick,
  setShowFilter
}) => {
  const [rowSelection,setRowSelection]=useState({});
  const usersList = useMemo(() => {
    return list ?? [];
  }, [list]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "memberEmail",
        header: "User Email",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "companyName",
        header: "Company",
        grow:true,
        size: 150,
      },
      {
        accessorKey: "memberRole",
        header: "Role",
        grow:true,
        size: 150,
      },
    ],
    []
  );
  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0;

  const isPrevButtonDisabled = page === 1;

  const handleBack = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col lg={12}>
          {location.pathname?.includes("manageusers") &&<Table
        columns={columns}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        handleToggle={handleToggle}
        onEditClick={onEditClick}
        isLoadingList={isLoadingList}
        data={usersList}
        handleBack={handleBack}
        handleNext={handleNext}
        isNextButtonDisabled={isNextButtonDisabled}
        isNextPrevDisabled={isPrevButtonDisabled}
        handleDelete={handleDeleteUsers}
        type={"users"}
        showModalFilter={setShowFilter}
        hideRowActions
      />}
            {/* <Table
              items={list ?? []}
              isLoadingList={isLoadingList}
              changedCount={changedCount}
              handleChangeValue={handleChangeValue}
              handleOpenModal={handleToggle}
              actionDisabled={actionDisabled}
              handleDeleteUsers={handleDeleteUsers}
              isOnboarding={false}
              handleChangeStatus={handleChangeStatus}
              isModalOpen={isModalOpen}
              showModal={() => showModal()}
              hideModal={() => hideModal()}
              control={control}
              controls={controls}
              handleReset={clear}
              handlecChangeDropdown={handlecChangeDropdown}
              handleBack={handleBack}
              handleNext={handleNext}
              refetchList={handleCreate}
              isNextButtonDisabled={isNextButtonDisabled}
              isNextPrevDisabled={isPrevButtonDisabled}
              sort={sort}
              onSearch={onSearch}
              setSort={setSort}
              deleteMutation={deleteMutation}
              onEditClick={onEditClick}
            /> */}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
