import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { toast } from "react-toastify";
import { ManageInvoicesLineItemsComp } from "../../components/Content/ManageInvoicesLineItemsComp.js";
import { useInvoiceLineItemsAPI } from "../../hooks/useInvoiceLineItemsAPI";
import { useParams } from "react-router";
import { ShimmeredDetailsList ,
  CommandBar,
  } from "@fluentui/react";
import {
  invoiceColumns,
  onRenderColumns,
} from "../Content/InvoiceTable/utils.jsx";
import { SelectionMode } from "@fluentui/react/lib/DetailsList";
import { useQueryClient } from "react-query";
import styled from "styled-components";
const CONTROL = {
  Group: "",
  Company: "",
  Email: "",
};
const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20;
  position: sticky;
  top: 64px;
  z-index: 9;
  background-color: #fff;
`;
 
const LeftAction = styled.div`
  display: flex;
`;
 
const RightAction = styled.div`
  display: flex;
  align-items: center;
`;
 
const InvoiceLineItems = ({
  invoiceRecord,
  handleClose,
  setInvoiceRecord,
  handleSuccess,
  onDownLoadClick,
  onPaymentClick
}) => {
  const { id } = useParams();
  const [invoiceId, setInvoiceId] = useState([]);
  const queryClient = useQueryClient();
  useEffect(() => {
    setInvoiceId(invoiceRecord.id);
  }, [invoiceRecord]);
  const SEARCH_DATA = {};
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState([]);
 
  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const [controls, setControl] = useState({ ...CONTROL });
 
  const onSearch = () => {
    setPage(1);
    setSearchData({
      GroupLookupId: controls["GroupLookupId"],
      company: controls["company"],
    });
  };
  const {
    data: list,
    copyLineItems,
    dupList,
    isLoadingList,
    changedCount,
    actionDisabled,
    handleChangeValue,
    showModal,
    hideModal,
    updateMutation,
    cmInvoices
  } = useInvoiceLineItemsAPI({
    enabled: true,
    sort,
    ...searchData,
    id,
    invoiceId,
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [showDisputeUpdate,setShowDisputeUpdate]=React.useState(false);
  const onEditClick = () => {
    setOpenModal(true);
  };
  const getIsUpdate = () => {
    if (dupList) {
      let isupdate =
        list?.filter((val, index) => {
          return val.isInDispute != dupList[index]?.isInDispute;
        })?.length > 0;
      return isupdate;
    }
    return false;
  };

  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };
 
  const handlecChangeDropdown = (key, val) => {
    const copyControl = { ...controls };
 
    copyControl[key] = val;
    setControl((prev) => ({ ...prev, ...copyControl }));
  };
  const updateDisputeNotes = () => {
    let isError=false
    setShowDisputeUpdate(true)
    const payload = {
      lineItems: [],
    };
    list.forEach((item, index) => {
      if (
        item.isInDispute != dupList[index].isInDispute ||
        item.disputeNotes != dupList[index].disputeNotes
      ) {
        if(item.isInDispute && !item.disputeNotes){
          isError=true
        }
        payload.lineItems.push({
          ...{
            invoiceId: invoiceRecord.id,
            lineItemId: item.itemNo,
            isInDispute: item.isInDispute,
            notes: item.disputeNotes,
            type: item.type,
            amount: item.amount,
            quantity: item.quantity,
            // isAgreement:
            //   item.type !== "tm"
            //     ? false
            //     : (item.quantity * item.amount) > 0
            //     ? true
            //     : false,
          },
        });
      }
    });
 
    if(isError){
      setShowDisputeUpdate(false)
      return toast.error("Please provide dispute notes for disputed items")
    }
    updateMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["LineItems"],
          refetchType: "all",
        });
        let newList = list.filter((i) => i.isInDispute) ?? [];
        let noDisputeAmtList = list.filter((i) => !i.isInDispute) ?? [];
        if (invoiceRecord) {
          invoiceRecord.amount_Dispute = newList
            .map((i) => i.amount)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          invoiceRecord.amount_Undispute = noDisputeAmtList
            .map((i) => i.amount)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          setInvoiceRecord({ ...invoiceRecord });
          handleSuccess?.(invoiceRecord);
          setTimeout(()=>{setShowDisputeUpdate(false);},500)
        }
        toast.success("Invoice updated successfully");
      },
      onError: (error) => {
        toast.error(error.message || "Something went wrong");
        changeCursor("");
        setShowDisputeUpdate(false);
      },
    });
  };
  const getListItems = (listItems) => {
    const isCMType = listItems?.filter((j) => j.type == "CM")?.length;
    return listItems?.map((i) => {
      return { ...i, isInDispute: (isCMType||cmInvoices?.length) ? false : i.isInDispute };
    });
  };
  return (
    <Modal open={open} onClose={handleClose} isCustomize>
      <h5 style={{ marginLeft: 16, marginTop: 8 }}>Invoice</h5>
      <div style={{ marginLeft: 16 }}>
        <ShimmeredDetailsList
          columns={invoiceColumns({
            onEditClick: () => {},
            isDetail: true,
            onDownLoadClick,
            onPaymentClick
          }).filter((i) => i.key != "Action")}
          items={[invoiceRecord].concat(cmInvoices??[])}
          enableShimmer={isLoadingList}
          onRenderItemColumn={(item, index, column) => {
            return onRenderColumns(item, index, column, [], true);
          }}
          selectionMode={SelectionMode.none}
        ></ShimmeredDetailsList>
      </div>
      <ActionBox>
         <LeftAction>
      <h5 style={{ marginLeft: 16, marginTop: 16, marginBottom: -40,height:'44px' }}>
        Line Items
      </h5>
      </LeftAction>
      { getIsUpdate() && (
          <RightAction>
            <CommandBar
           style={{ marginLeft: 16, marginTop: 16, marginBottom: -40 }}
              items={[
                {
                  key: "update",
                  text: `Update`,
                  iconProps: { iconName: "edit" },
                  disabled:showDisputeUpdate,
                  onClick: () => {
                    updateDisputeNotes?.();
                  },
                },
              ]}
              ariaLabel="Inbox actions"
              primaryGroupAriaLabel="Email actions"
              farItemsGroupAriaLabel="More actions"
            />
          </RightAction>
        )}
    </ActionBox>
     {/* <ToastContainer /> */}
     <ManageInvoicesLineItemsComp
        list={getListItems(list)}
        isLoadingList={isLoadingList}
        changedCount={changedCount}
        page={page}
        setPage={setPage}
        handleChangeValue={handleChangeValue}
        actionDisabled={actionDisabled}
        handlecChangeDropdown={handlecChangeDropdown}
        isModalOpen={openModal}
        showModal={showModal}
        hideModal={hideModal}
        sort={sort}
        setSort={setSort}
        onSearch={onSearch}
        onEditClick={onEditClick}
        copyLineItems={copyLineItems}
        isUpdateButton={getIsUpdate()}
        updateDisputeNotes={updateDisputeNotes}
        dupList={getListItems(dupList)}
        onDownLoadClick={onDownLoadClick}
        cmInvoices={cmInvoices}
      />
      {/* <div>
        <h5 style={{ marginLeft: "57%", marginTop: Math.min(((list?.length+1)*50)+40,340) }}>
          Total Balance:{" "}
          {new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(invoiceRecord.amount_Due || 0)}
        </h5>
      </div> */}
    </Modal>
  );
};
 
export default InvoiceLineItems;
