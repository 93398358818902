import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import App from './App.jsx';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/msal';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './config/queryClient';
import Layout from './components/Layout';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.Fragment>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <App />
        </Layout>
      </QueryClientProvider>
    </MsalProvider>
  </React.Fragment>
);
