import { useMemo } from "react";
import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const getSharePointReports = (token, page, id) =>
  customAPI(token)
    .get(`api/Reports/M365Report?id=${id}&SubReportType=OpsGenieShift`)
    .then((res) => {
      return res?.data;
    });

export const useSharePointReports = ({ page = 1, id }) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery(
    `GET-SHAREPOINT-REPORTS-${page}`,
    () => getSharePointReports(token, page, id)
  );

  const normlizedData = useMemo(() => data?.entities, [data]);

  return { data: normlizedData, isLoading, refetch };
};
