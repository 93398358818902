
import { Controller } from "react-hook-form";
import { TextField } from "@fluentui/react";
import * as React from "react";
import styles from "./style.module.scss";


export const ControlledTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error },
      }) => (
        <TextField
          {...props}
          required={false}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error && error.message}
          defaultValue={undefined}
          className={props.required ? styles.inputCommon : ""}
        />
      )}
    />
  );
};
