import { useMemo, useState, useEffect } from "react";
import { customAPI } from "../services/api";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";

const getInvoiceLineItems = (token, page, filter, sort, id, invoiceId) =>
  customAPI(token)
    .get(
      `api/Reports/${id}/Invoices/${invoiceId}/LineItems`
      //  {
      //   params: {
      //     size,
      //     page,
      //     filter,
      //     orderBy:
      //       sort?.length && sort?.filter((i) => i.value)?.length
      //         ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
      //         : undefined,
      //     sort:
      //       sort?.length && sort?.filter((i) => i.value)?.length
      //         ? `${sort.filter((i) => i.value).map((k, i) => `${k.value}`)}`
      //         : undefined,
      //   },
      // }
    )
    .then((res) => {
      return {
        invoiceLineItems: res?.data?.invoiceLineItems,
        totalAmount: res?.data?.totalAmount,
        cmInvoices: res?.data?.cmInvoices,
      };
    });

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    itemNo: item.itemNo,
    code: item.code,
    description: item.description,
    quantity: item.quantity,
    unitPrice: item.unitPrice,
    amount: item.amount,
    isInDispute: item.isInDispute,
    disputeNotes: item.disputeNotes,
    isEdited: false,
    isShowTextBox: false,
    type: item.type,
    id: item.itemNo,
    credit:item.credit,
    isDisputeEligible:item.isDisputeEligible
  }));

  return newItems;
};
const normalizercmInvoices = (items) => {
  const newItems = items?.map((item) => ({
    id: item.id,
    invoiceNumber: item.invoiceNumber,
    invoiceType: item.invoiceType,
    reference: item.reference,
    attention: item.attention,
    amount: item.amount,
    amount_Credit: item.amount_Credit,
    amount_Paid: item.amount_Paid,
    amount_Dispute: item.amount_Dispute,
    ccy: item.ccy,
    amount_Due: item.amount_Due,
    status: item.status,
    date: item.date ? moment(item.date).format("MM/DD/YYYY") : "",
    amount_Undispute: item.amount_Undispute,
    isDisputeEligible:item.isDisputeEligible
  }));
  return newItems;
};
const updateDisputeNotes = (data, token) => {
  return customAPI(token).patch("api/Reports/Invoices/UpdateInvoiceItem", data);
};

export const useInvoiceLineItemsAPI = ({
  page = 1,
  sort,
  id,
  invoiceId,
}) => {
  const { token } = useSelector((store) => store.auth);
  let filter = ``;

  if (filter.toString().indexOf(" and ") == 0)
    filter = filter.replace(" and ", "");
  const { data, isLoading, refetch } = useQuery([`LineItems`, invoiceId], () =>
    getInvoiceLineItems(token, page, filter, sort, id, invoiceId)
  );
  const [lineItems, setLineItemes] = useState([]);
  const handleChangeValue = async (id, key, value) => {
    // if (!value && key !="notes")
    // return;
    let newItems = [...lineItems];

    const selectedItem = newItems.find((item) =>
      item.itemNo.toString().includes(id)
    );
    selectedItem[key] = value;
    selectedItem.isEdited = true;
    selectedItem.isShowTextBox = true;
    newItems = newItems.map((item) =>
      item.itemNo.toString().includes(id) ? selectedItem : item
    );

    await setLineItemes([...newItems]);
  };
  const updateMutation = useMutation(
    (data) => updateDisputeNotes(data, token),
    {
      onError: (error) => {
        if (error.response.status == 409) {
          error.message = "Duplicate  entry exists";
          return error;
        } else {
          error.message = "Something went wrong";
          return error;
        }
      },
    }
  );
  useEffect(() => {
    if (data?.invoiceLineItems) {
      const normlizedData = normalizer(data?.invoiceLineItems);
      if (normlizedData) setLineItemes([...normlizedData]);
    }
  }, [data]);
  const normlizedDupData = useMemo(
    () => normalizer(data?.invoiceLineItems),
    [data?.invoiceLineItems]
  );
  const normlizedcmInvoicesData = useMemo(
    () => normalizercmInvoices(data?.cmInvoices),
    [data?.cmInvoices]
  );
  const totalAmount = useMemo(() => data?.totalAmount, [data?.totalAmount]);
  return {
    data: lineItems,
    totalAmount: totalAmount,
    copyLineItems: lineItems,
    dupList: normlizedDupData,
    cmInvoices: normlizedcmInvoicesData,
    isLoadingList: isLoading,
    refetchList: refetch,
    handleChangeValue,
    updateMutation,
  };
};
