import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  Icon,
} from "@fluentui/react";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ControlledDropdown } from "../ControlledDropdown";
import { useCompaniesAPI } from "../../hooks/useCompaniesAPI";
import styles from "./style.module.scss";
const GroupFilterModal = ({
  isModalOpen,
  hideModal,
  control,
  controls,
  handlecChangeDropdown,
  onSearch,
  clear,
}) => {
  const { data: list } = useCompaniesAPI({
    page: 1,
    enabled: true,
    sort: null,
    searchData: {},
  });
  const getCompanyListLu = () => {
    return list?.map((item) => {
      return { ...{ key: item.groupId, text: item.title } };
    });
  };
  const titleId = useId("title");
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}> Filter Groups</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className={contentStyles.body}>
        <ControlledDropdown
          key={"Company" + open}
          isComboBox
          label="Company"
          name="Company"
          control={control}
          onChange={(e, val) => {
            handlecChangeDropdown("company", val?.key);
          }}
          options={getCompanyListLu()}
         
          selectedKey={controls.company}
        />
         <div className={styles.field}>
                <ControlledDropdown
                  key={"SecurityGroup" + open}
                  // required={true}
                  label="Security Group"
                  name="SecurityGroup"
                  control={control}
                  isComboBox
                  selectedKey={controls.securityGroup}
                  onChange={(e, val) => {
                    if (val) {
                      
                      handlecChangeDropdown("securityGroup", val?.key);
                    } else {
                     
                      handlecChangeDropdown("securityGroup", "");
                    }
                  }}
                  options={[
                    { key: "Security", text: "Security" },
                    { key: "Service", text: "Service" },
                    { key: "Finance", text: "Finance" },
                  ]}
                  // rules={{ required: "This field is required" }}
                />
              </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <PrimaryButton onClick={() => clear()}>
            <Icon iconName="Delete" />
            &nbsp;Clear Filter
          </PrimaryButton>
          <PrimaryButton onClick={() => onSearch()}>
            <Icon iconName="Search" />
            &nbsp;Apply Filter
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

const cancelIcon = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxWidth: "400px",
    minWidth: 400,
  },
  header: [
    {
      flex: "1 1 auto",
      borderTop: `4px solid rgb(79, 82, 145)`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default GroupFilterModal;
