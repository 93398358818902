import { useEffect, useMemo, useState } from "react";
import { customAPI } from "../services/api";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
const size = 20;

const getMangeUsers = (token, page, company,UserEmail, sort) =>
  customAPI(token)
    .get("api/ClientUsers", {
      params: {
        companyId:company ,
        MemberEmail:UserEmail,
        size,
        page,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => res?.data?.data);

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    id: item?.id,
    companyName:item?.companyName,
    memberRole:item.memberRole,
    cwCompanyId:item?.cwCompanyId,
    companyId:item?.companyId,
    memberEmail:item?.memberEmail
  }));

  return newItems;
};
const deleteUser = (data, token) => {
  return customAPI(token).delete("api/ClientUser?id=" + data.id);
};

export const useManageUsersAPI = ({
  page = 1,
  sort,
  company,
  UserEmail
  
}) => {
  const { token } = useSelector((store) => store.auth);
  const [queryVariables, setQueryVariables] = useState({
    // GroupLookupId: GroupLookupId,
    company: company,
    UserEmail: UserEmail,
  });
  useEffect(() => {
    setQueryVariables({
      ...{ 
         company: company, UserEmail: UserEmail },
    });
  }, [company, UserEmail]);
  const deleteMutation = useMutation((data) => deleteUser(data, token));
  const handleDeleteUsers = async (list, onSuccess) => {
    try {
      list.forEach((user) => {
        deleteMutation.mutate(user, {
          onSuccess: () => {
            refetch();
            onSuccess();
          },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  let filter = ``;


  if (filter.toString().indexOf(" and ") == 0)
    filter = filter.replace(" and ", "");
  const { data, isLoading, refetch } = useQuery(
    [
      `GET-s-${filter}-${page}-${sort?.map(
        (i) => `${i.key}-${i.value}`
      )}`,
      queryVariables,
    ],
    () => getMangeUsers(token, page, company,UserEmail, sort)
  );
  const normlizedData = useMemo(() => normalizer(data), [data]);

  return { data: normlizedData, isLoadingList: isLoading, refetchList:refetch, handleDeleteUsers };
};
