import { useMemo, useState, useEffect } from "react";
import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";
const size = 20;
const getMangeInvoices = (token, page, filter, sort, id,searchData) =>
  customAPI(token)
    .get(`api/Reports/${id}/Invoices`, {
      params: {
        size:searchData.status=="Outstanding Only" || searchData.status=="Disputed Only"?1000:size,
        page:searchData.status=="Outstanding Only"|| searchData.status=="Disputed Only"?1:page,
        filter,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => {
      return {
        totalDispute: res?.data?.totalDispute,
        invoices: res?.data?.invoices,
      };
    });

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    id: item.id,
    invoiceNumber: item.invoiceNumber,
    invoiceType: item.invoiceType,
    reference: item.reference,
    attention: item.attention,
    amount: item.amount,
    amount_Credit: item.amount_Credit,
    amount_Paid: item.amount_Paid,
    amount_Dispute: item.amount_Dispute,
    ccy: item.ccy,
    status: item.status,
    amount_Due: item.amount_Due,
    date: item.date ? moment(item.date).format("MM/DD/YYYY") : "",
    amount_Undispute: item.amount_Undispute,
  }));
  return newItems;
};


export const useInvoicesAPI = ({ id, page = 1, sort, searchData }) => {
  const { token } = useSelector((store) => store.auth);

  let filter = ``;
  if (searchData)
    if (searchData.type) {
      filter = `
    type = '${searchData.type}'  ${`${
        searchData.fromDate && searchData.toDate
          ? `and  date >= [${moment(searchData.fromDate).format(
              "YYYY-MM-DD"
            )}] and date <= [${moment(searchData.toDate).format("YYYY-MM-DD")}]`
          : ""
      }`} ${`${
        searchData.fromAmount && searchData.toAmount
          ? `and  balance >=  ${searchData.fromAmount} and balance <= ${searchData.toAmount}`
          : ""
      }`} ${`${
        searchData.status 
          ?  searchData.status=="Outstanding Only"?`and  balance > 0`:`and   ${searchData.status}`
          : ""
      }`}
      `;
    } else {
      filter = `  ${`${
        searchData.fromDate && searchData.toDate
          ? `date >= [${moment(searchData.fromDate).format(
              "YYYY-MM-DD"
            )}] and date <=  [${moment(searchData.toDate).format("YYYY-MM-DD")}]`
          : ""
      }`} ${`${
        searchData.fromAmount && searchData.toAmount
          ? `and  balance >=  ${searchData.fromAmount} and balance <=  ${searchData.toAmount}`
          : ""
      }`} ${`${
        searchData.status 
          ?  searchData.status=="Outstanding Only"?`and  balance > 0`:`and   ${searchData.status}`
          : ""
      }`}
    `;
    }

  const { data, isLoading, refetch } = useQuery(
    [`GET-s-${filter}-${page}-${sort?.map((i) => `${i.key}-${i.value}`)}`, id],
    () => getMangeInvoices(token, page, filter, sort, id,searchData)
  );
  const [listItems, setListItemes] = useState([]);
  const handleChangeValue = async (id, key, value) => {
    let newItems = [...listItems];

    const selectedItem = newItems.find((item) =>
      item.id.toString().includes(id)
    );
    selectedItem[key] = value;
    await setListItemes([...newItems]);
  };
  const totalDisputeAmount = useMemo(() => data?.totalDispute, [data]);
  useEffect(() => {
    const normlizedData = normalizer(data?.invoices);
    if (normlizedData) setListItemes([...normlizedData]);
    else {
      setListItemes([]);
    }
  }, [data]);

  return {
    data: listItems,
    isLoadingList: isLoading,
    refetchList: refetch,
    totalDispute: totalDisputeAmount,
    handleChangeValue,
  };
};
