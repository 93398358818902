import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../Loader";
import { BASE_URL } from "../../services/api";

const ManageLinkIframe = () => {
  const { id } = useParams();
  const [srcUrl, setSrcUrl] = useState("");
  const { token } = useSelector((store) => store.auth);
  const [loading, setLoading] = useState(false);
  const getIframeSrc = () => {
    setLoading(true);
    fetch(`${BASE_URL}/api/Reports/${id}/LinkReport`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setSrcUrl(data?.reportUrl);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getIframeSrc();
  }, []);

  return (
    <div>
      {loading && <Loader></Loader>}
      <iframe src={srcUrl} title="GeeksforGeeks"></iframe>
    </div>
  );
};

export default ManageLinkIframe;
