import { useEffect, useMemo, useState } from "react";
import { customAPI } from "../services/api";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
const size = 20;

const getMangeReports = (
  token,
  page,
  company,
  GroupLookupId,
  sort,
  isForLookups
) =>
  customAPI(token)
    .get("api/Reports", {
      params: {
        size: !isForLookups ? size : 1000000000,
        page,
        CompanyID: company,
        GroupId: GroupLookupId,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => res?.data?.data);

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    title: item?.name,
    groupName: item?.groupName,
    companyName: item?.companyName,
    type: item?.reportType,
    id: item?.id,
    groupId: item?.groupID,
    domoDashboardID: item?.domoDashboardID,
    domoDashboardUser: item?.domoDashboardUser,
    domoFilter: item?.domoFilter,
    pbiWorkspaceId: item?.pbiWorkspaceId,
    pbiReportId: item?.pbiReportId,
    reportUrl: item?.reportUrl,
    subReportType: item?.subReportType,
    category:item?.category,
    sequence: item?.sequence ,
  }));

  return newItems;
};
const deleteReport = (data, token) => {
  return customAPI(token).delete("api/Reports/" + data.id);
};

export const useManageReportsAPI = ({
  page = 1,
  sort,
  GroupLookupId,
  company,
  isForLookups,
}) => {
  const [queryVariables, setQueryVariables] = useState({
    GroupLookupId: GroupLookupId,
    company: company,
  });
  useEffect(() => {
    setQueryVariables({
      ...{ GroupLookupId: GroupLookupId, company: company },
    });
  }, [GroupLookupId, company]);
  const { token } = useSelector((store) => store.auth);
  const deleteMutation = useMutation((data) => deleteReport(data, token));
  const handleDeleteReports = async (list, onSuccess) => {
    try {
      list.forEach((report) => {
        deleteMutation.mutate(report, {
          onSuccess: () => {
            refetch();
            onSuccess();
          },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  let filter = ``;
  // if (company) filter += `Fields/company eq ${company} `;
  // if (GroupLookupId) filter += `${company? `and ` : ``} Fields/GroupLookupId eq ${GroupLookupId} `;

  const { data, isLoading, refetch } = useQuery(
    [
      `GET-MANAGE-REPORTS-${filter}-${page}-${sort?.map(
        (i) => `${i.key}-${i.value}`
      )}`,
      queryVariables,
    ],
    () =>
      getMangeReports(token, page, company, GroupLookupId, sort, isForLookups)
  );

  const normlizedData = useMemo(() => normalizer(data), [data]);

  return {
    data: normlizedData,
    isLoadingList: isLoading,
    refetchList: refetch,
    handleDeleteReports,
  };
};
