import React, { useState } from "react";
import styles from "./style.module.scss";
import { ActionButton, Icon, Modal } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../ControlledTextField";
import { ControlledDropdown } from "../ControlledDropdown";
import { toast } from "react-toastify";
import { useCompaniesAPI } from "../../hooks/useCompaniesAPI";
import { ControlledSpinButton } from "../ControlledSpinButton";
const CONTROL = {
  Name: "",
  Company: "",
  SecurityGroup: "",
  SequenceOrder: 0,
  DisplayName: "",
};

const CreateGroup = ({
  open,
  handleClose,
  onSuccess,
  createMutation,
  updateMutation,
  groupRecord,
}) => {
  const { data: list } = useCompaniesAPI({
    page: 1,
  });
  const [isEditable, setIsEditable] = useState(false);
  const [controls, setControl] = useState({ ...CONTROL });
  const [disabled, setDisabled] = useState(false);
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      Name: "",
      Company: "",
      DisplayName: "",
      SequenceOrder: 0,
      SecurityGroup: "",
    },
  });

  React.useEffect(() => {
    if (!groupRecord.id) {
      setIsEditable(false);
      setControl({ ...CONTROL });
      Object.keys(controls).forEach((i) => {
        setValue(i, "");
      });
    }
  }, []);

  const getCompanyListLu = () => {
    return list?.map((item) => {
      return { ...{ key: item.groupId, text: item.title } };
    });
  };

  React.useEffect(() => {
    if (groupRecord.id) {
      reset();
      setIsEditable(true);
      controls.Name = groupRecord.groupName;
      controls.Company = groupRecord.companyLookupId;
      controls.DisplayName = groupRecord.displayName;
      controls.SequenceOrder = groupRecord.sequence;
      controls.SecurityGroup = groupRecord.securityGroup;
      setControl({ ...controls });
      Object.keys(controls).forEach((i) => {
        setValue(i, controls[i]);
      });
    }
  }, [groupRecord.id]);
  const onSubmit = (data) => {
    setDisabled(true);
    changeCursor("wait");
    const payload = {
      Name: data.Name,
      companyID: data.Company,
      // Title: data.Name,
      DisplayName: data.DisplayName,
      Sequence: parseInt((data.SequenceOrder&& data.SequenceOrder!=="") ?data.SequenceOrder: "0"),
      SecurityGroup: data.SecurityGroup,
    };
    if (!isEditable)
      createMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in group creation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    else {
      updateMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in group  updation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    }
  };

  const handlecChangeDropdown = (key, val) => {
    const copyControl = { ...controls };

    copyControl[key] = val;
    setControl((prev) => ({ ...prev, ...copyControl }));
    setValue(key, val);
  };

  return (
    <Modal
      isOpen={open}
      onDismiss={handleClose}
      dragOptions={{ dragHandleSelector: "form" }}
    >
      {/* <ToastContainer /> */}
      <form
        className={styles.wrapper}
        onSubmit={handleSubmit(onSubmit)}
        id={"formId"}
      >
        <div className={styles.header}>
          <h4 className={styles.title}>{isEditable ? "Edit" : "Add"} Group </h4>
          <Icon
            iconName={"Cancel"}
            className={styles.close}
            onClick={handleClose}
          ></Icon>
        </div>

        <div className={styles.body}>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <h5 className={styles.groupTitle}>Group Details</h5>
     
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Group Name"
                  name="Name"
                  control={control}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Display Name"
                  name="DisplayName"
                  control={control}
                  rules={{ required: "This field is required" }}
                />
              </div>
              
              <div className={styles.field}>
                <ControlledDropdown
                  key={"SecurityGroup" + open}
                  required={true}
                  label="Security Group"
                  name="SecurityGroup"
                  control={control}
                  isComboBox
                  selectedKey={controls.SecurityGroup}
                  onChange={(e, val) => {
                    if (val) {
                      setValue("SecurityGroup", val.key);
                      handlecChangeDropdown("SecurityGroup", val?.key);
                    } else {
                      setValue("SecurityGroup", "");
                      handlecChangeDropdown("SecurityGroup", "");
                    }
                  }}
                  options={[
                    { key: "Security", text: "Security" },
                    { key: "Service", text: "Service" },
                    { key: "Finance", text: "Finance" },
                  ]}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledSpinButton
                  min={0}
                  labelPosition={0}
                  label="Sequence Order"
                  name="SequenceOrder"
                  control={control}
                  value={controls.SequenceOrder}
                  onChange={(e, val) => {
                    if (val) {
                      setValue("SequenceOrder", val);
                      handlecChangeDropdown("SequenceOrder", val);
                    } else {
                      setValue("SequenceOrder", 0);
                      handlecChangeDropdown("SequenceOrder", 0);
                    }
                  }}
                />
              </div>
          
              <div className={styles.field}>
                <ControlledDropdown
                  key={"Company" + open}
                  required={true}
                  label="Company"
                  name="Company"
                  control={control}
                  isComboBox
                  selectedKey={controls.Company}
                  onChange={(e, val) => {
                    if (val) {
                      setValue("Company", val.key);
                      handlecChangeDropdown("Company", val?.key);
                    } else {
                      setValue("Company", "");
                      handlecChangeDropdown("Company", "");
                    }
                  }}
                  options={getCompanyListLu()}
                  rules={{ required: "This field is required" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ActionButton disabled={disabled} type="submit" form="formId">
            Submit
          </ActionButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateGroup;
