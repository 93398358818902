import { useMsal } from '@azure/msal-react';
import {
  DefaultButton,
  Modal,
  mergeStyleSets,
  getTheme,
  FontWeights,
  IconButton,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useOnClickOutside from '../../hooks/useOutsideClick';
import styles from './ProfilePopup.module.scss';
import { useNavigate } from "react-router";
import help from "../../assets/RFA Services Help.docx";
import useFeedback from "../../hooks/useFeedback";
import { toast } from "react-toastify";

const ProfilePopup = () => {
  const ref = useRef();
  const { instance } = useMsal();
  const {
    createMutation,
  } = useFeedback();
  const theme=getTheme()
  const [showProfile, setShowProfile] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [disabled,setDisabled] = useState(false);
  const [comment,setComment] = useState('');
  const [errorMessage,setErrorMessage]=useState('');
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width:500
    },
    header: [
      {
        flex: '1 1 auto',
        borderTop: `4px solid rgb(79, 82, 145)`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px'
      }
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 }
      }
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 24px',
      margin:'auto',
      marginBottom:4
    },
    button: {
      width: 'auto',
    },
    cancel: {
      width: 'auto',
      backgroundColor: 'transparent',
      color: '65224' 
    }
  });
  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginBottom: '4px',
    },
    rootHovered: {
      color: theme.palette.neutralDark
    }
  };
  useEffect(()=>{
    if(!showFeedback){
      setComment('');
      setErrorMessage('')
    }
  },[showFeedback])

  const {
    currentUser: { name },
  } = useSelector((store) => store.userReducer);
  const { currentUser: user } = useSelector((store) => store.userReducer);

  const fname = name?.split(' ')?.[0];
  const lname = name?.split(' ')?.[1];

  const abbreviationName = (fname?.[0] || 'U') + (lname?.[0] || 'U');

  const togglePopup = () => setShowProfile((prev) => !prev);

  const handleLogout = () => {
    instance.logout({
      postLogoutRedirectUri: '/'
    });
  };
  const handleDownload = async () => {
    const link = document.createElement("a");
    link.href = help;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useOnClickOutside(ref, () => setShowProfile(false));
  const navigate = useNavigate();
  const handleSubmit = () => {
    if(!comment){
      setErrorMessage("Comment Required")
     return  
    }
    setErrorMessage("")
    const payload = {
      comments: comment,
    };
    setDisabled(true);
    createMutation.mutate(payload, {
      onSuccess: () => {
        setDisabled(false);
        setShowFeedback(false);
        toast.success("Feedback submitted successfully");
      },
      onError: (error) => {
        setDisabled(false);
        toast.error(error.message || "Something went wrong in  feedback submission")}
    });
  };

  return (
    <div className={styles.container} ref={ref}>
      <DefaultButton className={styles.button} onClick={togglePopup}>
        <span className={styles.thumb}>{abbreviationName}</span>
        <span className={styles.fullname}>{name}</span>
      </DefaultButton>
      {showProfile && (
        <div className={styles.popup}>
          <div className={styles.header}>
            <span>Profile</span>
            <span className={styles.logout} onClick={handleLogout}>
              Logout
            </span>
          </div>
          <div className={styles.body}>
            <span className={styles.thumb}>{abbreviationName}</span>
            <div className={styles.profile}>
              <h4>{name}</h4>
            </div>
          </div>
          <div className={styles.footer}>
            {user.isAdmin && (
              <>
                              <ul className={styles.list}>
                  <li onClick={() => navigate("/managecompanies")}>
                    Manage Companies
                  </li>
                </ul>
                <ul className={styles.list}>
                  <li onClick={() => navigate("/managegroups")}>
                    Manage Groups
                  </li>
                </ul>
                <ul className={styles.list}>
                  <li onClick={() => navigate("/managewidgets")}>
                    Manage Widgets
                  </li>
                </ul>
                <ul className={styles.list}>
                  <li onClick={() => navigate("/manageusers")}>Manage Users</li>
                </ul>
              </>
            )}
             {user.isExecutive && (
              <>
                          
                <ul className={styles.list}>
                  <li onClick={() => navigate("/report/PBI/6979D18A-EE45-40B4-AE72-6B3124F804C6")}>RFA Exec. Report</li>
                </ul>
              </>
            )}
              <ul className={styles.list}>
              <li onClick={() => setShowFeedback(true)}>Feedback</li>
            </ul>
            <ul className={styles.list}>
              <li onClick={() => handleDownload()}>Help</li>
            </ul>
          </div>
        </div>
      )}
     {showFeedback && 
<Modal
   titleAriaId="Feedback"
   isOpen={showFeedback}
   onDismiss={()=>
   {
   setShowFeedback(false)
   }}
   isBlocking={false}
   containerClassName={contentStyles.container}
   >
   <div className={contentStyles.header}>
      <h5 id="Feedback">Feedback</h5>
      <IconButton
      styles={iconButtonStyles}
      iconProps={{ iconName: 'Cancel' }}
      ariaLabel="Close popup modal"
      onClick={()=>{
      setShowFeedback(false)
      }}
      />
   </div>
   <div className={contentStyles.body}>
      <div className={styles.field}>
         <TextField
         required={true}
         label="Comment"
         name="Comment"
         value={comment}
         errorMessage={errorMessage&& errorMessage}
         rules={{ required: "This field is required" }}
         onChange={(e, val) =>
         setComment(val)
         }
         resizable
         multiline
         />
      </div>
   </div>
   <div className={contentStyles.footer}>
      <PrimaryButton className={contentStyles.cancel}   onClick={()=>{
         setShowFeedback(false)
         }}>
         Cancel
      </PrimaryButton>
      <PrimaryButton disabled={disabled} className={contentStyles.button} onClick={handleSubmit}>
         Submit
      </PrimaryButton>
   </div>
</Modal>
}
    </div>
  );
};

export default ProfilePopup;
