import styled from "styled-components";
import {  Toggle } from "@fluentui/react";
import { TextField } from "@fluentui/react";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "@fluentui/react/lib/Link";

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LiWrapper = styled.div`
  transition: 0.4s ease all;

  h6 {
    font-size: 0.9em;
    line-height: 18px !important;
    margin: 0;
  }

  p {
    margin: 0;
    color: grey;
    font-size: 0.9em;
    line-height: 12px;
  }
  && {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1px 0;

    width: 100% !important;
    text-align: left;
    font-size: 14;
  }

  ${({ active }) =>
    active
      ? `
  background: white;
  border-left: 3px solid #0078D4;
  background-color: rgba(0,0,0,0.07);
  `
      : `background: none;`}
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const renderColumnTitle = (children, props) => {
  let tabularData = [];
  if (props.showTitleAsReadbleStream) {
    tabularData = props.description?.split("||");
  }
  return props.showTitleAsReadbleStream ? (
    <TooltipHost
      content={
        <div>
          <div className="d-flex">
            <div className="p-1">
              <p>
                <strong>Ticket # : </strong> {tabularData[0]},
              </p>
            </div>
            <div className="p-1">
              <p>
                <strong>Date : </strong> {tabularData[1]}
              </p>
            </div>
          </div>
          <p>
            <strong>Description : </strong> {tabularData[2]}
          </p>
        </div>
      }
    >
      {children}
    </TooltipHost>
  ) : (
    children
  );
};
const Title = ({ title, ...props }) => {
  if (props.tooltip)
    return (
      <div style={{ display: "flex" }}>
        <TooltipHost content={props.tooltip}>
          <Icon iconName="Info" style={{ cursor: "pointer" }} />
        </TooltipHost>
        <div
          style={{ minWidth: "50px", minHeight: "20px", marginLeft: "10px" }}
        >
          {title}
        </div>
      </div>
    );
  return (
     renderColumnTitle(<span
      onClick={(e) => {
        props.fieldName === "disputeNotes"
          ? props.onColumnClick?.(e, props)
          : () => {};
      }}
      style={{
        display: "block",
        textAlign: props.subType == "number" ? "right" : "left",
        width: "100%",
        fontWeight:props.isBold?"bold":'inherit'
      }}
      title={props.showTitle && !props.showTitleAsReadbleStream ? title : ""}
    >
      {props.subType === "number"
        ? new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(title)
        : props.showTitleAsReadbleStream&&props.fieldName === "description"  ?(props.description?.split("||")[2]):title}
    </span>,props)
  );
};
const EditItem = ({ ...props }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "50px", minHeight: "20px", marginLeft: "10px" }}>
        <TooltipHost content="Preview">
          <Icon
            iconName="Preview"
            style={{ cursor: "pointer" }}
            onClick={(ev) => {
              ev.preventDefault();
              props.onEdit?.(props.item);
            }}
          />
        </TooltipHost>
      </div>
    </div>
  );
};

// const Assignee = ({ email, fullname, assignees }) => {
//   if (assignees?.length)
//     return (
//       <ListContainer>
//         {assignees?.map((item,index) => {
//           const fullname =
//             item?.displayName || [item?.name, item?.surname].join(" ");

//           return (
//             <LiWrapper key={index}>
//               <TooltipHost content={item.email}>
//                 <Persona
//                   hidePersonaDetails
//                   text={fullname}
//                   size={PersonaSize.size24}
//                   imageAlt={fullname}
//                 />
//               </TooltipHost>
//               <Labels>
//                 <h6>{fullname}</h6>
//                 {/* <p>{item.email}</p> */}
//               </Labels>
//             </LiWrapper>
//           );
//         })}
//       </ListContainer>
//     );
//   return (
//     <LiWrapper>
//       <TooltipHost content={email}>
//         <Persona
//           hidePersonaDetails
//           text={fullname}
//           size={PersonaSize.size24}
//           imageAlt={fullname}
//         />
//       </TooltipHost>
//       <Labels>
//         <h6>{fullname}</h6>
//         {/* <p>{email}</p> */}
//       </Labels>
//     </LiWrapper>
//   );
// };

const TooltipTextField = (props) => {
  return (
    <div style={{ width: "300px" }}>
      <TooltipHost content={props.tooltip}>
        <TextField {...props} description="" maxLength={"100"} />
      </TooltipHost>
    </div>
  );
};


export const onRenderColumns = (
  item,
  index,
  column,
  handleChangeValue,
  accessUpdate,
  isShowTextBox,
  copyLineItems,
  dupList,type
) => {
  const isSelected =
    item.isInDispute != (dupList && dupList[index]?.isInDispute);
  let props = {
    key: column.key,
    title: item[column.key],
    disabled: !isSelected || !accessUpdate,
    // defaultChecked: item.isApplicable,
    defaultChecked: false,
    selectedKey: item[column.key],
    value: item[column.key],
    runJob: [],
    type: "string",
    onEdit: column.onEditClick,
    item: item,
    onColumnClick: column.onColumnClick,
    fieldName: column.fieldName,
    subType: column.data,
    showTitle: column.key === "reference" ||column.key=="description"  ? true : column.tooltip,
    isBold:column.key=="heading"?true:false,
    showTitleAsReadbleStream:type && item?.type?.toLowerCase()=="tm" ?true:false
  };
  const onChangeInput = (e) => {
    let val = e.target.value;
    let clm = column.key;
    handleChangeValue(item.itemNo, clm, val);
  };
  props.onChange = onChangeInput;
  // const Renderer =
  //   copyLineItems && copyLineItems[index]?.isInDispute
  //     ? item.isShowTextBox
  //       ? column.InputComponent
  //       : column.RenderComponent
  //     : isSelected
  //     ? column.InputComponent
  //     : column.RenderComponent;
  const Renderer = isSelected ? column.InputComponent : column.RenderComponent;

  return <Renderer {...item} {...props} data-selection-disabled={true} />;
};

export const invoiceColumns = ({ onEditClick, isDetail, onDownLoadClick }) => {
  return [
    {
      key: "invoiceNumber",
      name: "Invoice #",
      fieldName: "invoiceNumber",
      maxWidth: 80,
      minWidth: 80,
      isChangable: false,
      RenderComponent: Title,
      onRender: (item) => {
        return isDetail ? (
          <>
            <Icon
              iconName="StatusCircleInner"
              styles={{
                root: {
                  marginRight: "4px",
                  color:
                    item.amount_Undispute && parseFloat(item.amount_Undispute) > 0
                      ? "red"
                      : "green",
                },
              }}
            ></Icon>
            <span title={item.invoiceNumber}>{item.invoiceNumber}</span>
          </>
        ) : (
          <>
            <Icon
              iconName="StatusCircleInner"
              styles={{
                root: {
                  marginRight: "4px",
                  color:
                    item.amount_Undispute && parseFloat(item.amount_Undispute) > 0
                      ? "red"
                      : "green",
                },
              }}
            ></Icon>
            <Link key={item.invoiceNumber} onClick={() => onEditClick(item)}>
            <span title={item.invoiceNumber}>{item.invoiceNumber}</span>
            </Link>
          </>
        );
      },
    },
    {
      key: "file",
      name: "PDF",
      fieldName: "file",
      minWidth: 36,
      maxWidth: 36,
      onColumnClick: () => {},
      onRender: (item) => (
        <TooltipHost content={`Download file`}>
          <Icon
            iconName="download"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDownLoadClick?.(item);
            }}
          ></Icon>
        </TooltipHost>
      ),
    },
    {
      key: "date",
      name: "Date",
      fieldName: "date",
      maxWidth: 75,
      minWidth: 75,
      isChangable: false,
      RenderComponent: Title,
      onRender: (item) => {
        return <span>{item.date}</span>;
      },
    },
    {
      key: "invoiceType",
      name: "Type",
      fieldName: "invoiceType",
      maxWidth: 75,
      minWidth: 75,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "reference",
      name: "Reference",
      fieldName: "reference",
      maxWidth: 220,
      minWidth: 220,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },

    // {
    //   key: "status",
    //   name: "Status",
    //   fieldName: "status",
    //   maxWidth: 90,
    //   minWidth: 75,
    //   isChangable: false,
    //   InputComponent: Title,
    //   RenderComponent: Title,
    // },
    {
      key: "ccy",
      name: "CCY",
      fieldName: "ccy",
      maxWidth: 65,
      minWidth: 65,
      data: "string",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "amount",
      name: "Amount",
      fieldName: "amount",
      maxWidth: 65,
      minWidth: 65,
      data: "number",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "amount_Credit",
      name: "Credit",
      fieldName: "amount_Credit",
      maxWidth: 65,
      minWidth: 65,
      data: "number",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "amount_Paid",
      name: "Paid",
      fieldName: "amount_Paid",
      maxWidth: 65,
      minWidth: 65,
      data: "number",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "amount_Dispute",
      name: "Dispute",
      fieldName: "amount_Dispute",
      maxWidth: 65,
      minWidth: 65,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      data: "number",
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "amount_Due",
      name: "Total",
      fieldName: "amount_Due",
      maxWidth: 65,
      minWidth: 65,
      data: "number",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },

    {
      key: "amount_Undispute",
      name: "Unpaid Balance",
      fieldName: "amount_Undispute",
      maxWidth: 165,
      minWidth: 105,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      data: "number",
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    // {
    //   key: "payment",
    //   name: "Payment",
    //   fieldName: "payment",
    //   maxWidth: 125,
    //   minWidth: 95,
    //   isChangable: false,
    //   InputComponent: Title,
    //   RenderComponent: Title,
    //   data: "number",
    //   onRender: (item) => {
    //     return (
    //     <PrimaryButton onClick={()=>onPaymentClick(item)}>Pay</PrimaryButton>
    //     );
    //   },
    // },

    // {
    //   key: "Action",
    //   name: "Action",
    //   fieldName: "Action",
    //   isResizable: false,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 100,
    //   minWidth: 75,
    //   isChangable: false,
    //   RenderComponent: EditItem,
    //   onEditClick: onEditClick,
    // },
  ];
};
export const invoiceLineItemsColumns = ({
  onColumnClick,
  isInDisputebutton,
  onDownLoadClick,
  isDisable
}) => {
  return [
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      maxWidth: 90,
      minWidth: 90,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "code",
      name: "Ref",
      fieldName: "code",
      maxWidth: 90,
      minWidth: 90,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRender: (item) => {
        return item.type == "CM" ? (
          <Link key={item.code} onClick={() => {
            onDownLoadClick?.(item);
          }}>
            {item.code}
          </Link>
        ) : (
          item.code
        );
      },
    },
    {
      key: "description",
      name: "Description",
      fieldName: "description",
      maxWidth: 250,
      minWidth: 250,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "quantity",
      name: "Quantity",
      fieldName: "quantity",
      maxWidth: 100,
      minWidth: 100,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },

    {
      key: "unitPrice",
      name: "Unit Price",
      fieldName: "unitPrice",
      maxWidth: 75,
      minWidth: 75,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      data: "number",
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "amount",
      name: "Amount",
      fieldName: "amount",
      maxWidth: 75,
      minWidth: 75,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      data: "number",
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "credit",
      name: "Credit",
      fieldName: "credit",
      maxWidth: 65,
      minWidth: 65,
      data: "number",
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
      onRenderHeader: (props) => {
        return (
          <span className="txtRgt" {...props}>
            {props.column.name}
          </span>
        );
      },
    },
    {
      key: "isInDispute",
      name: "Is in Dispute",
      fieldName: "isInDispute",
      maxWidth: 100,
      minWidth: 100,
      isChangable: true,
      InputComponent: Toggle,
      RenderComponent: Toggle,
      onRender: (item) => {
        return (
          <Toggle
            checked={item.isInDispute}
            disabled={isDisable}
            onClick={() => {
              isInDisputebutton(item);
            }}
          ></Toggle>
        );
      },
    },
    {
      key: "disputeNotes",
      name: "Dispute Notes",
      fieldName: "disputeNotes",
      maxWidth: 300,
      minWidth: 300,
      isChangable: false,
      InputComponent: TooltipTextField,
      RenderComponent: Title,
      onColumnClick: onColumnClick,
      tooltip: true,
    },
  ];
};
export const invoiceSummaryColumns = () => {
  return [
    {
      key: "heading",
      name: "Age",
      fieldName: "heading",
      maxWidth: 100,
      minWidth: 100,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "balance",
      name: "Total",
      fieldName: "balance",
      maxWidth: 90,
      minWidth: 90,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "disputeAmount",
      name: "Dispute",
      fieldName: "disputeAmount",
      maxWidth: 90,
      minWidth: 90,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "unDisputeBalance",
      name: "Unpaid Balance",
      fieldName: "unDisputeBalance",
      maxWidth: 150,
      minWidth: 150,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "disputedItemsCount",
      name: "Disputed Count",
      fieldName: "disputedItemsCount",
      maxWidth: 90,
      minWidth: 90,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
  ];
};


export const reportsColumns = ({ onColumnClick, sort, onEditClick }) => {
  return [
    {
      key: "title",
      name: "Report Title",
      fieldName: "title",
      ariaLabel: "Column operations for File type, Press to sort on File type",
      maxWidth: 250,
      minWidth: 250,
      isChangable: false,
      InputComponent: Title,
      RenderComponent: Title,
    },
    {
      key: "groupName",
      name: "Group",
      fieldName: "groupName",
      isResizable: true,
      isCollapsible: true,
      data: "number",
      maxWidth: 150,
      minWidth: 150,
      isChangable: false,
      InputComponent: TextField,
      RenderComponent: Title,
    },
    {
      key: "type",
      name: "Type",
      fieldName: "type",
      isResizable: true,
      isCollapsible: true,
      data: "string",
      maxWidth: 150,
      minWidth: 150,
      isChangable: false,
      InputComponent: TextField,
      RenderComponent: Title,
      isSorted:
        sort?.map((i) => i.key).includes("Type") &&
        sort?.filter((i) => i.key === "Type")[0].value,
      isSortedDescending:
        sort?.map((i) => i.key).includes("Type") &&
        sort?.filter((i) => i.key === "Type")[0].value === "desc",
      onColumnClick: onColumnClick,
    },
    // {
    //   key: "domoDashboardID",
    //   name: "Domo Dashboard ID",
    //   fieldName: "domoDashboardID",
    //   isResizable: true,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 150,
    //   minWidth: 150,
    //   isChangable: false,
    //   InputComponent: TextField,
    //   RenderComponent: Title,
    // },
    // {
    //   key: "domoDashboardUser",
    //   name: "Domo Dashboard User",
    //   fieldName: "domoDashboardUser",
    //   isResizable: true,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 150,
    //   minWidth: 150,
    //   isChangable: false,
    //   InputComponent: TextField,
    //   RenderComponent: Title,
    // },
    // {
    //   key: "domoFilter",
    //   name: "Domo Filter",
    //   fieldName: "domoFilter",
    //   isResizable: true,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 150,
    //   minWidth: 150,
    //   isChangable: false,
    //   InputComponent: TextField,
    //   RenderComponent: Title,
    // },
    // {
    //   key: "pbiWorkspaceId",
    //   name: "PBI Workspace Id",
    //   fieldName: "pbiWorkspaceId",
    //   isResizable: true,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 150,
    //   minWidth: 150,
    //   isChangable: false,
    //   InputComponent: TextField,
    //   RenderComponent: Title,
    // },
    // {
    //   key: "pbiReportId",
    //   name: "PBI Report Id",
    //   fieldName: "pbiReportId",
    //   isResizable: true,
    //   isCollapsible: true,
    //   data: "string",
    //   maxWidth: 150,
    //   minWidth: 150,
    //   isChangable: false,
    //   InputComponent: TextField,
    //   RenderComponent: Title,
    // },
    {
      key: "Action",
      name: "Action",
      fieldName: "Action",
      isResizable: false,
      isCollapsible: true,
      data: "string",
      maxWidth: 150,
      minWidth: 150,
      isChangable: false,
      RenderComponent: EditItem,
      onEditClick: onEditClick,
    },
  ];
};
