import React, { useState } from "react";
import { ActionButton, Icon, Modal } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../ControlledTextField";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
const CONTROL = {
  Name: "",
  CwCompanyID: "",
  Domain: "",
};

const CreateCompany = ({
  open,
  handleClose,
  onSuccess,
  createMutation,
  updateMutation,
  companyRecord,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [controls, setControl] = useState({ ...CONTROL });
  const [disabled, setDisabled] = useState(false);
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      Name: "",
      CwCompanyID: "",
      Domain: "",
    },
  });

  React.useEffect(() => {
    if (!companyRecord.id) {
      setIsEditable(false);
      setControl({ ...CONTROL });
      Object.keys(controls).forEach((i) => {
        setValue(i, "");
      });
    }
  }, []);
  React.useEffect(() => {
    if (companyRecord.id) {
      reset();
      setIsEditable(true);
      controls.Name = companyRecord.companyName;
      controls.CwCompanyID = companyRecord.cwCompanyID;
      controls.Domain = companyRecord.domain;
      setControl({ ...controls });
      Object.keys(controls).forEach((i) => {
        setValue(i, controls[i]);
      });
    }
  }, [companyRecord.id]);
  const onSubmit = (data) => {
    setDisabled(true);
    changeCursor("wait");
    const payload = {
      Name: data.Name,
      CwCompanyID:parseInt(data.CwCompanyID??0),
     // Title: data.Name,
      Domain: data.Domain,
      // CreatedUser: user.username,
      // ModifiedUser: user.username,
    };
    if (!isEditable)
      createMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in company creation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    else {
      updateMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in company  updation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    }
  };



  return (
    <Modal
      isOpen={open}
      onDismiss={handleClose}
      dragOptions={{ dragHandleSelector: "form" }}
    >
      {/* <ToastContainer /> */}
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>

          <h4 className={styles.title}>
            {isEditable ? "Edit" : "Add"} Company{" "}
            {/* {isEditable ? <Icon iconName="Edit" /> : <Icon iconName="Add" />} */}
          </h4>
          <Icon
            iconName={"Cancel"}
            className={styles.close}
            onClick={handleClose}
          ></Icon>
        </div>

        <div className={styles.body}>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <h5 className={styles.groupTitle}>Company Details</h5>
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Name"
                  name="Name"
                  control={control}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledTextField
                  required={false}
                  label="CWID"
                  name="CwCompanyID"
                  type="number"
                  min={0}
                  control={control}
                  // rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Domain"
                  name="Domain"
                  control={control}
                  rules={{ required: "This field is required" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ActionButton disabled={disabled} type="submit">
            Submit
          </ActionButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateCompany;
