import  { useMemo } from "react";
import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
const size = 10000;

const getCompanies = (token, page, filter, sort) =>
  customAPI(token)
    .get("api/Company", {
      params: {
        size,
        page,
        filter,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => res?.data?.data);

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    title: item?.name,
    groupId: item?.id,
    cwCompanyID:item?.cwCompanyID
  }));

  return newItems;
};

export const useCompaniesAPI = ({ page = 1,  sort, filter }) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery(
    `GET-COMPANIES-${filter}-${page}-${sort?.map(
      (i) => `${i.key}-${i.value}`
    )}`,
    () => getCompanies(token, page, filter, sort)
  );

  const normlizedData = useMemo(() => normalizer(data), [data]);

  return { data: normlizedData, isLoading, refetch };
};
