export const statuses = {
  NOT_CERTIFIED: 'Not certified',
  PENDING: 'Applied - pending results',
  CERTIFIED: 'Certified',
  EXPIRED: 'Certification expired'
};

export const statusKeys = {
  NOT_CERTIFIED: 'NOT_CERTIFIED',
  PENDING: 'PENDING',
  CERTIFIED: 'CERTIFIED',
  EXPIRED: 'EXPIRED'
};
