import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { customAPI } from "../services/api";

let totalCount = 0;

const getReportItem = (id) =>
  customAPI.get("api/Reports/" + id).then((res) => {
    return res;
  });

const createReport = (data, token) => {
  return customAPI(token).post("api/Reports", data);
};

const editReport = (id, data, token) => {
  return customAPI(token).put("api/Reports/" + id, data);
};
const deleteReport = (id, data, token) => {
  return customAPI(token).delete("api/Reports/" + id);
};

const useManageReportAPI = ({ id = null }) => {
  const { token } = useSelector((store) => store.auth);

  const {
    data: detail,
    refetch: refetchDetail,
  } = useQuery(`GET-REPORT-ITEM-${id}`, () => getReportItem(id), {
    enabled: !!id,
  });

  const createMutation = useMutation((data) => createReport(data, token), {
    onError: (error) => {
      if (error.response.status == 409) {
        error.message = "Duplicate Report entry exists";
        return error;
      } else {
        error.message = "Something went wrong";
        return error;
      }
    },
  });
  const updateMutation = useMutation((data) => editReport(id, data, token));
  const deleteMutation = useMutation((data) => deleteReport(id, data, token));

  return {
    totalCount: totalCount,
    detail,
    refetchDetail,
    deleteMutation,
    createMutation,
    updateMutation,
  };
};

export default useManageReportAPI;
