import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const getSchedular = (token, refreshObj) =>
  customAPI(token)
    .get(
      `api/Reports/${refreshObj?.id}/ShiftSchedule?shiftId=${refreshObj?.group}&interval=${refreshObj?.intervel}&intervalUnit=${refreshObj?.intervalUnit}`
    )
    .then((res) => res?.data.data.result);

export const useSchedular = ({ refreshObj, enable }) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery(
    ["GET-SCHEDULAR", refreshObj],
    () => getSchedular(token, refreshObj),
    { enabled: !enable }
  );
  return { data: data, isLoading: isLoading, refetch };
};
