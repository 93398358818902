import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const getSchedularGroups = (token, refreshObj) =>
  customAPI(token)
    .get(`api/Reports/${refreshObj?.id}/ScheduleLookup`)
    .then((res) => res?.data?.data);

export const useSchedularGroups = ({ refreshObj }) => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery(
    ["GET-SCHEDULAR-GROUPS", refreshObj],
    () => getSchedularGroups(token, refreshObj)
  );
  return { data: data, isLoadingGroups:  isLoading, refetch };
};
