import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
export const ProtectedRoute = ({ children }) => {
  const { currentUser: user } = useSelector((store) => store.userReducer);
  const isAuthenticated = useIsAuthenticated();
  if (
    !isAuthenticated ||
    (isAuthenticated && !user.isAdmin && Object.hasOwnProperty.call(user,"isAdmin"))
  ) {
    return <Navigate to="/" replace />;
  } else {
    if (!Object.hasOwnProperty.call(user,"isAdmin"))
      setTimeout(() => {
        if (
          !isAuthenticated ||
          (isAuthenticated && !user.isAdmin && Object.hasOwnProperty.call(user,"isAdmin"))
        ) {
          return <Navigate to="/" replace />;
        }
      }, 50);
  }
  return children;
};
