import {  initializeIcons } from '@fluentui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from '../../redux/store';
import { PageLayout } from '../PageLayout';

initializeIcons();

const Layout = ({ children }) => {
  const persistor = persistStore(store);

  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <ThemeProvider theme={customTheme}> */}
            <PageLayout>{children}</PageLayout>
          {/* </ThemeProvider> */}
        </PersistGate>
      </Provider>
    </Router>
  );
};

export default Layout;
