import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  expiryTime: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setExpiryTime: (state, { payload }) => {
      state.expiryTime = payload;
    },
  },
});

// each case under reducers becomes an action
export const { setToken ,setExpiryTime} = authSlice.actions;
export default authSlice.reducer;
