import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  DetailsListLayoutMode,
  ConstrainMode,
  TooltipHost,
  Sticky,
  StickyPositionType,
  ScrollablePane,
  PrimaryButton,
  Icon,
} from "@fluentui/react";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { CheckboxVisibility, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { useBoolean } from "@fluentui/react-hooks";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import styled from "styled-components";
import {
  onRenderColumns,
  invoiceColumns,
  invoiceLineItemsColumns,
} from "./utils";
import ActionsHeader from "./ActionsHeader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import WarningModal from "./WarningModal";
import { redirectUri } from "../../../config/msal";

const Wrapper = styled.div`
margin-top: 20px;
`;
const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20;
  position: absolute;
  margin-bottom: 24px;
  z-index: 9;
  background-color: #fff;
`;

const LeftAction = styled.div`
  display: flex;
`;
export const Table = ({
  items,
  handleOpenModal,
  changedCount,
  handleDeleteUsers,
  handleDeleteReports,
  control,
  controls,
  actionDisabled,
  handlecChangeDropdown,
  onSearch,
  handleReset,
  handleBack,
  handleNext,
  isNextButtonDisabled,
  isNextPrevDisabled,
  isLoadingList,
  sort,
  type,
  onEditClick,
  isModalOpen,
  handleChangeValue,
  totalDispute,
  isUpdateButton,
  isShowTextBox,
  copyLineItems,
  updateDisputeNotes,
  dupList,
  totalLineItems,
  undisputedBalance,
  balance,
  isLoading,
  ccy,
  onDownLoadClick,
  isLineItems,
  cmInvoices,
  disputeObj
}) => {
  const { isOpenMenu } = useSelector((store) => store.userReducer);
  const [disabled, setDisabled] = useState(false);
  const [leftMargin,setLeftMargin]=useState("20px")
   const [paneHeight,setPaneHeight]=useState(0)
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const onColumnClick = (ev, column) => {
    if (column.fieldName === "disputeNotes") {
      handleChangeValue(column.item.itemNo, "isShowTextBox", true);
    }
  };
  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal },
  ] = useBoolean(false);

  const [selectionDetails, setSelectionDetails] = useState();

  const [selectedItems, setSelectedItems] = useState();

  const selectedIds = useMemo(
    () => selectedItems?.map((item) => item.id),
    [selectedItems]
  );


  const onDeleteUsers = useCallback(() => {
    showDeleteModal();
  }, []);

  const postDeleteRecords = useCallback(() => {
    setDisabled(true);
    changeCursor("wait");
    if (!type) handleDeleteUsers(selectedIds, onSuccessDelete);
    else handleDeleteReports(selectedIds, onSuccessDelete);
  }, [selectedIds]);
  useEffect(() => {
    if (isOpenMenu) {
      setTimeout(() => {
        setLeftMargin("320px");
      }, 400);
    } else {
      setLeftMargin("20px");
    }
  }, [isOpenMenu]);
  useEffect(() => {
    if (document.getElementsByClassName("ms-ScrollablePane")[0]?.clientHeight) {
      setPaneHeight(
        document.getElementsByClassName("ms-ScrollablePane")[0]?.clientHeight
      );
    }
  }, [document.getElementsByClassName("ms-ScrollablePane")[0]]);
 useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (
          document.getElementsByClassName("ms-ScrollablePane")[0]?.clientHeight
        ) {
          setPaneHeight(
            document.getElementsByClassName("ms-ScrollablePane")[0]
              ?.clientHeight
          );
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    setPaneHeight(
      document.getElementsByClassName("ms-ScrollablePane")[0]?.clientHeight
    );
  }, [isModalOpen]);

  // useEffect(() => {
  //   let divs = document.getElementsByClassName("ms-DetailsHeader-cellIsCheck");
  //   if (!divs) return;
  //   divs[0].addEventListener("click", (e) => {
  //     if (
  //       divs[0]
  //         .getElementsByClassName("ms-Check")[0]
  //         .classList.contains("is-checked")
  //     ) {
  //       setSelectedItems([]);
  //       setSelectionDetails(0);
  //     } else {
  //       let temp = items;
  //       setSelectedItems(temp);
  //       setSelectionDetails(temp.length);
  //     }
  //   });
  // }, [document.getElementsByClassName("ms-DetailsHeader-cellIsCheck"), items]);

  // useEffect(() => {
  //   document.addEventListener("click", function (evt) {
  //     let flyoutEl = document.getElementsByClassName("ms-DetailsRow-check")[0],
  //       targetEl = evt.target.parentElement.parentElement; // clicked element

  //     do {
  //       if (targetEl == flyoutEl) {
  //         return;
  //       }
  //       // Go up the DOM
  //       targetEl = targetEl.parentNode;
  //     } while (targetEl);
  //   });
  // }, [isRowRendered]);

  function getKey(item) {
    return item?.id;
  }

  let canSelectItem = (item) => {
    return item.amount_Undispute && parseFloat(item.amount_Undispute) > 0;
  };

  const selection = useMemo(
    () =>
      new Selection(
        {
        onSelectionChanged: () => {
          let arr = selection
            ?.getSelection();
          const selectionDetail = getSelectionDetails();
          setSelectedItems(arr || []);
          setSelectionDetails(selectionDetail);
          return;
        },
        canSelectItem: canSelectItem.bind(this)
      }),
    []
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     let divs = document.getElementsByClassName("ms-DetailsRow");
  //     if (!divs) return;
  //     for (let x = 0; x < divs.length; x++) {
  //       divs[x].onclick = function (e, ind) {
  //         if (e.target.parentElement.classList.contains("ms-Check")) {
  //           let selectedUserName =
  //             e.target.parentElement.parentElement.parentElement.parentElement
  //               .getElementsByClassName("ms-DetailsRow-fields")[0]
  //               .getElementsByClassName("ms-DetailsRow-cell")[0]
  //               .getElementsByTagName("span")[0].innerText;
  //           let temp1 = [
  //             ...items.filter(
  //               (elem, index) => elem["ProductCode"] == selectedUserName
  //             ),
  //           ];
  //           if (e.target.parentElement.classList.contains("is-checked")) {
  //             let filtered = selectedItems.filter(
  //               (el) => el["ProductCode"] != selectedUserName
  //             );
  //             setSelectedItems(filtered);
  //             setSelectionDetails(filtered.length);
  //           } else {
  //             let temp2 = [];
  //             if (selectedItems) {
  //               temp2 = [...selectedItems, ...temp1];
  //             } else {
  //               temp2 = [...temp1];
  //             }

  //             setSelectedItems(temp2);
  //             setSelectionDetails(temp2.length);
  //           }
  //         }
  //       };
  //     }
  //   }, 50);
  // }, [
  //   selectedItems && selectedItems.length,
  //   document.getElementsByClassName("ms-DetailsRow"),
  //   document.getElementsByClassName("ms-DetailsRow").length,
  //   items,
  //   isRowRendered,
  // ]);

  useEffect(() => {
    if (isModalOpen) {
      selection.setAllSelected(false);
      setSelectedItems([]);
      setSelectionDetails(0);
    }
  }, [isModalOpen]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     let divs = document.getElementsByClassName("ms-DetailsHeader-cellName");
  //     if (!divs) return;
  //     for (var x = 0; x < divs.length; x++) {
  //       divs[x].onclick = function (e) {
  //         setSelectedItems([]);
  //         setSelectionDetails(0);
  //       };
  //     }
  //   }, 50);
  // }, [
  //   selectedItems && selectedItems.length,
  //   document.getElementsByClassName("ms-DetailsRow"),
  //   document.getElementsByClassName("ms-DetailsRow").length,
  //   items,
  //   isRowRendered,
  // ]);

  console.log("selction .get sel --->", selection?.getSelection());

  function getSelectionDetails() {
    const selectionCount = selection?.getSelectedCount() || 0;

    return selectionCount;
  }

  // const postDelete = () => {
  const onSuccessDelete = () => {
    setDisabled(false);
    changeCursor("");
    selection.setItems([], true);
    selection.toggleAllSelected();

    setSelectedItems([]);
    setSelectionDetails(0);
    hideDeleteModal();
    toast.success("Successfully deleted");
  };

  const onReset = () => {
    handleReset();
  };

  // const handleOpenGrid = (val) => {
  //   setIsRowRendered(!isRowRendered);
  //   if (!val.isCollapsed) {
  //     setOpenGrid([]);
  //   } else if (val.isCollapsed && val.name.includes("General")) {
  //     setOpenGrid(["General"]);
  //     document
  //       .getElementsByClassName("ms-ScrollablePane--contentContainer")[0]
  //       .scroll({ top: 0, behavior: "smooth" });
  //   } else if (val.isCollapsed && val.name.includes("IT")) {
  //     setOpenGrid(["IT"]);
  //     document
  //       .getElementsByClassName("ms-ScrollablePane--contentContainer")[0]
  //       .scroll({ top: 0, behavior: "smooth" });
  //   } else {
  //     setOpenGrid(["Others"]);
  //     document
  //       .getElementsByClassName("ms-ScrollablePane--contentContainer")[0]
  //       .scroll({ top: 0, behavior: "smooth" });
  //   }
  // };
  const isInDisputebutton = (val) => {
    handleChangeValue(val?.itemNo, "isInDispute", !val.isInDispute);
  };
  const getMarginTop = () => {
    return paneHeight > 1050 ? (isModalOpen? (1186):(1066)) : (paneHeight) + (isModalOpen? 88:16);
  };
  const renderList = (children) => {
    return <ScrollablePane
        style={{
          left: isOpenMenu&&!isLineItems ? leftMargin : (!isLineItems?"20px":8),
          top:isLineItems?`${Math.max(175,175+((cmInvoices?cmInvoices.length:0)*50))}px`: "420px",
            maxHeight:isLineItems?'100%':"60%" ,        
        }}
      >
        {children}
      </ScrollablePane>};

      const onPaymentClick = () => {
        if (selection?.getSelection()?.length > 0) {
          let invoiceObj = [];
          selection?.getSelection()?.forEach((record) => {
            invoiceObj.push({
              CCY: record.ccy,
              InvAmount: record.amount_Undispute,
              InvoiceNum: record.invoiceNumber,
              InvoiceId: record.id,
            });
          });
          localStorage.setItem("InvoicePayInfo", JSON.stringify(invoiceObj));
          window.open(`${redirectUri}invoicepayment`, "_blank");
        } else {
          toast.warning("Please select atleast one invoice to  proceed");
        }
      };
  return (
    <>
      <Wrapper className="manage-table">
        {/* <ToastContainer /> */}
        {!isLineItems &&
          <ActionsHeader
            control={control}
            controls={controls}
            onReset={onReset}
            updateDisputeNotes={updateDisputeNotes}
            isUpdateButton={isUpdateButton}
            isNextPrevDisabled={isNextPrevDisabled}
            handleBack={handleBack}
            isNextButtonDisabled={isNextButtonDisabled}
            handlecChangeDropdown={handlecChangeDropdown}
            handleNext={handleNext}
            handleOpenModal={(val) => {
              setSelectedItems([]);
              setSelectionDetails(0);
              return handleOpenModal(val);
            }}
            actionDisabled={actionDisabled}
            handleDeleteUsers={() => onDeleteUsers()}
            changedCount={changedCount}
            selectionDetails={selectionDetails}
            selectedIds={selectedIds}
            onSearch={onSearch}
            type={type}
            totalDispute={totalDispute}
            totalLineItems={totalLineItems}
            undisputedBalance={undisputedBalance}
            balance={balance}
            ccy={ccy}
            isLoading={isLoading}
            disputeObj={disputeObj}
            onPaymentClick={onPaymentClick}
            selectedItems={selectedItems}
          />
        }
        <div>         
         {renderList(<MarqueeSelection selection={selection}>
            <div id="onboarding_list1">
              <ShimmeredDetailsList
                enableCellEdit={false}
                items={items}
                columns={
                  type
                    ? invoiceLineItemsColumns({
                        onColumnClick,
                        isInDisputebutton,
                        onDownLoadClick,
                        isDisable:items?.filter(i=>i.type==="CM")?.length || cmInvoices?.length ||  items?.filter(i=>!i.isDisputeEligible)?.length
                      })
                    : invoiceColumns({
                        onColumnClick,
                        sort,
                        onEditClick,
                        onDownLoadClick,
                      }).filter((i) => i.key != "payment")
                }
                layoutMode={DetailsListLayoutMode.fixedColumns}
                 constrainMode={ConstrainMode.unconstrained}
                selectionMode={type ?SelectionMode.none:SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                getKey={getKey}
                setKey="items"
                isHeaderVisible={true}
                selection={selection}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                  renderedWindowsAhead: 0,
                  renderedWindowsBehind: 0,
                }}
                enableShimmer={isLoadingList}
                onRenderDetailsHeader={renderFixedDetailsHeader}
                selectionPreservedOnEmptyClick={true}
                selectionZoneProps={{selectionClearedOnSurfaceClick:false}}
                // onActiveItemChanged={(val) => handleRowClick(val)}
                onShouldVirtualize={() => false}
                onRenderItemColumn={(item, index, column) => {
                  return onRenderColumns(
                    item,
                    index,
                    column,
                    handleChangeValue,
                    true,
                    isShowTextBox,
                    copyLineItems,
                    dupList,
                    type
                  );
                }}
              />
            </div>
          </MarqueeSelection>)}
        </div>
        {!isLineItems&&<ActionBox style={{marginTop:getMarginTop()}}>
                <LeftAction>
                <PrimaryButton
                    style={{ display: "flex", alignItems: "center", marginRight: 10 }}
                    onClick={handleBack}
                    disabled={isNextPrevDisabled}
                  >
                    <Icon
                      iconName="Back"
                      style={{
                        marginRight: 10,
                      }}
                    />
                    Previous
                  </PrimaryButton>
        
                  <PrimaryButton
                    onClick={handleNext}
                    disabled={isNextButtonDisabled}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Next
                    <Icon
                      iconName="Forward"
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </PrimaryButton>
        
                </LeftAction>
                </ActionBox>}
        <WarningModal
          title={`Are you sure to delete ${type ? "widgets" : "users"}?`}
          description={`You are going to delete selected ${
            type ? "widgets" : "users"
          }!`}
          isOpen={isDeleteModalOpen}
          onClose={hideDeleteModal}
          onSubmit={() => postDeleteRecords()} //handleDeleteUsers(selectedIds, postDelete)}
          disabled={disabled}
        />
      </Wrapper>
    </>
  );
};

export const renderFixedDetailsHeader = (props, defaultRender) => {
  if (!props) {
    return null;
  }

  const onRenderColumnHeaderTooltip = (tooltipHostProps) => (
    <TooltipHost {...tooltipHostProps} />
  );
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );
};
