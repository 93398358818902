import { useMemo ,useState,useEffect } from "react";
import { customAPI } from "../services/api";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const size = 20;

const getMangeGroups = (token, page, sort, company,securityGroup) =>
  customAPI(token)
    .get("api/Groups", {
      params: {
        size,
        page,
        companyId:company ,
        SecurityGroup:securityGroup,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => res?.data?.data);

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    id: item?.id,
    companyName: item?.company,
    groupName: item?.name,
    companyLookupId: item?.companyID,
    createdUser: item?.createdUser,
    modifiedUser: item?.modifiedUser,
    displayName: item?.displayName,
    securityGroup: item?.securityGroup,
    sequence: item?.sequence ,
  }));

  return newItems;
};
const deleteGroup = (data, token) => {
  return customAPI(token).delete("api/Groups/" + data.id);
};

export const useManageGroupsAPI = ({ page = 1, sort,  company,securityGroup }) => {
  const { token } = useSelector((store) => store.auth);
  const [queryVariables, setQueryVariables] = useState({
    company: company,
    securityGroup: securityGroup,
  });
  useEffect(() => {
    setQueryVariables({
      ...{ 
         company: company, securityGroup: securityGroup },
    });
  }, [company, securityGroup]);
  const deleteMutation = useMutation((data) => deleteGroup(data, token),{
    onError: (error) => {
      if (error.response.status == 409) {
        toast.error(error.response.data.message);
        return error;
      } else {
        error.message = "Something went wrong";
        return error;
      }
    },
  });
  const handleDeleteGroups = async (list, onSuccess) => {
    try {
      list.forEach((user) => {
        deleteMutation.mutate(user, {
          onSuccess: () => {
            refetch();
            onSuccess();
          },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  // let filter = ``;
  // if (name) {
  //   filter = `Fields/Name eq ${name}`;
  // }
  const { data, isLoading, refetch } = useQuery(
    [`GET-groups-${page}-${sort?.map((i) => `${i.key}-${i.value}`)}`, queryVariables],
    () => getMangeGroups(token, page, sort, company,securityGroup)
  );
  const normlizedData = useMemo(() => normalizer(data), [data]);

  return {
    data: normlizedData,
    isLoadingList: isLoading,
    refetchList: refetch,
    handleDeleteGroups,
  };
};
