import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { customAPI } from "../services/api";

let totalCount = 0;

const getCompanyItem = (id) =>
  customAPI.get("api/Company/" + id).then((res) => {
    return res;
  });

const createCompany = (data, token) => {
  return customAPI(token).post("api/Company", data);
};

const editCompany = (id, data, token) => {
  return customAPI(token).put("api/Company/" + id, data);
};
const deleteCompany = (id, data, token) => {
  return customAPI(token).delete("api/Company/" + id);
};

const useManageComany = ({ id = null }) => {
  const { token } = useSelector((store) => store.auth);

  const { data: detail } = useQuery(
    `GET-COMPANY-ITEM-${id}`,
    () => getCompanyItem(id),
    {
      enabled: !!id,
    }
  );

  const createMutation = useMutation((data) => createCompany(data, token), {
    onError: (error) => {
      if (error.response.status == 409) {
        error.message = "Duplicate Company entry exists";
        return error;
      } else {
        error.message = "Something went wrong";
        return error;
      }
    },
  });

  const updateMutation = useMutation((data) => editCompany(id, data, token));
  const deleteMutation = useMutation((data) => deleteCompany(id, data, token));

  return {
    totalCount: totalCount,
    detail,
    deleteMutation,
    createMutation,
    updateMutation,
  };
};

export default useManageComany;
