import React, { useEffect, useState } from "react";
// import Modal from "../Modal";
import styles from "./style.module.scss";
import { ActionButton, Icon, Modal } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../ControlledTextField";
import { ControlledDropdown } from "../ControlledDropdown";
import { toast } from "react-toastify";
import { useCompaniesAPI } from "../../hooks/useCompaniesAPI";
import { useCompanyGroupsAPI } from "../../hooks/useCompanyGroupsAPI";
import { ControlledSpinButton } from "../ControlledSpinButton";
const CONTROL = {
  Group: "",
  Company: "",
  ReportType: "",
  PBIReportId: "",
  PBIWorkspaceId: "",
  DomoFilter: "",
  DomoDashboardID: "",
  DomoDashboardUser: "",
  Title: "",
  ReportUrl: "",
  SubReportType: "",
  Category: "",
  SequenceOrder: 0,
};
const CreateReport = ({
  open,
  handleClose,
  onSuccess,
  createMutation,
  reportRecord,
  updateMutation,
}) => {
  const SEARCH_DATA = {};

  const [page] = React.useState(1);
  const [isEditable, setIsEditable] = useState(false);
  const [sort] = React.useState([]);
  const [companyId, setCompanyId] = React.useState();
  const [controls, setControl] = useState({ ...CONTROL });
  const SEARCH_GROUP_DATA = {
    companyId: companyId,
  };
  const [searchData] = React.useState({ ...SEARCH_DATA });
  const [searchGroupData, setSearchGroupData] = React.useState({
    ...SEARCH_GROUP_DATA,
  });
  const { data: list } = useCompaniesAPI({
    page,
    enabled: true,
    sort,
    ...searchData,
  });
  const { data: groupsList } = useCompanyGroupsAPI({
    page,
    enabled: searchGroupData.companyId && searchGroupData.companyId != "",
    sort,
    filter: { ...searchGroupData },
  });
  const [disabled, setDisabled] = useState(false);
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const { control, handleSubmit, setValue,} = useForm({
    defaultValues: {
      CompanyLookupId: "",
      Group: "",
      Email: "",
      SequenceOrder: 0,
    },
  });

  useEffect(() => {
    if (!reportRecord.id) {
      setIsEditable(false);
      setControl({ ...CONTROL });
      Object.keys(controls).forEach((i) => {
        setValue(i, "");
      });
    }
  }, []);
  useEffect(() => {
    if (reportRecord.id) {
      setIsEditable(true);
      // controls.Company = groupsList?.filter(
      //   (item) => item.id == reportRecord.groupId
      // )[0]?.companyLookupId;
      controls.Company = list?.filter(
        (item) => item.title == reportRecord.companyName
      )[0]?.groupId;
      searchGroupData.companyId = controls.Company;
      setSearchGroupData({ ...searchGroupData });
      controls.Title = reportRecord.title;
      controls.SequenceOrder = reportRecord.sequence;
      controls.Group = reportRecord.groupId;
      if (reportRecord.type == "Domo") {
        controls.DomoDashboardUser = reportRecord.domoDashboardUser;
        controls.DomoDashboardID = reportRecord.domoDashboardID;
        controls.DomoFilter = reportRecord.domoFilter;
      }
      controls.ReportType = reportRecord.type;
      controls.Category = reportRecord.category;
      if (reportRecord.type == "PBI") {
        controls.PBIWorkspaceId = reportRecord.pbiWorkspaceId;
        controls.PBIReportId = reportRecord.pbiReportId;
      }
      if (reportRecord.type == "Link") {
        controls.ReportUrl = reportRecord.reportUrl;
      }
      // if (reportRecord.type == "M365") {
      //   controls.SubReportType = reportRecord.subReportType;
      // }
      setControl({ ...controls });
      Object.keys(controls).forEach((i) => {
        setValue(i, controls[i]);
      });
    }
  }, [reportRecord.id, groupsList?.length, list?.length]);
  const onSubmit = (data) => {
    setDisabled(true);
    changeCursor("wait");
    const payload = {
      Name: data.Title,
      GroupID: data.Group,
      ReportType: data.ReportType,
      PBIReportId: "None",
      PBIWorkspaceId: "None",
      DomoFilter: "None",
      DomoDashboardID: "None",
      DomoDashboardUser: "None",
      ReportUrl: "None",
      SubReportType: "None",
      Category: data.Category,
      Sequence: parseInt(
        data.SequenceOrder && data.SequenceOrder !== ""
          ? data.SequenceOrder
          : "0"
      ),
    };
    if (payload.ReportType == "Domo") {
      (payload.DomoDashboardUser = data.DomoDashboardUser || "None"),
        (payload.DomoDashboardID = data.DomoDashboardID || "None");
      payload.DomoFilter = data.DomoFilter || "None";
    }
    if (payload.ReportType == "PBI") {
      payload.PBIReportId = data.PBIReportId || "None";
      payload.PBIWorkspaceId = data.PBIWorkspaceId || "None";
    }
    if (payload.ReportType == "Link") {
      payload.ReportUrl = data.ReportUrl || "None";
    }

    if (!isEditable)
      createMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in report creation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    else {
      updateMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(
            error.message || "Something went wrong in report updation"
          );
          setDisabled(false);
          changeCursor("");
        },
      });
    }
  };

  const handlecChangeDropdown = (key, val) => {
    const copyControl = { ...controls };
    copyControl[key] = val;
    if (val == "Domo" && key == "ReportType") {
      setValue("PBIReportId", "");
      setValue("PBIWorkspaceId", "");
      setValue("ReportUrl", "");
    } else if (val == "PBI" && key == "ReportType") {
      setValue("DomoDashboardUser", "");
      setValue("DomoDashboardID", "");
      setValue("DomoFilter", "");

      setValue("ReportUrl", "");
    } else if (val == "M365" && key == "ReportType") {
      setValue("SubReportType", "");
      setValue("ReportUrl", "");
    }
    setControl((prev) => ({ ...prev, ...copyControl }));
    setValue(key, val);
  };
  const getCompanyListLu = () => {
    return list?.map((item) => {
      return { ...{ key: item.groupId, text: item.title } };
    });
  };
  const getGroupListLu = () => {
    return groupsList?.map((item) => {
      return { ...{ key: item.id, text: item.title } };
    });
  };

  return (
    // <Modal open={open} onClose={handleClose}>
    <Modal
      isOpen={open}
      onDismiss={handleClose}
      dragOptions={{ dragHandleSelector: "form" }}
    >
      {/* <ToastContainer /> */}
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>
          <h4 className={styles.title}>
            {isEditable ? "Edit" : "Add"} Widget{" "}
            {/* {isEditable ? <Icon iconName="Edit" /> : <Icon iconName="Add" />} */}
          </h4>
          <Icon
            iconName={"Cancel"}
            className={styles.close}
            onClick={handleClose}
          ></Icon>
        </div>

        <div className={styles.body}>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <h5 className={styles.groupTitle}>Widget Details</h5>
              <div className={styles.field}>
                <ControlledDropdown
                  key={"Company" + open}
                  required={true}
                  label="Company"
                  name="Company"
                  control={control}
                  isComboBox
                  selectedKey={controls.Company}
                  onChange={(e, val) => {
                    if (val) {
                      setValue("Company", val.key);
                      handlecChangeDropdown("Company", val?.key);
                      setValue("Group", "");
                      searchGroupData.companyId = val?.key;
                      setSearchGroupData({ ...searchGroupData });
                      setCompanyId(val?.key);
                    } else {
                      setValue("Company", "");
                      handlecChangeDropdown("Company", "");
                      setValue("Group", "");
                      searchGroupData.companyId = "";
                      setSearchGroupData({ ...searchGroupData });
                      setCompanyId("");
                    }
                  }}
                  options={getCompanyListLu()}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledDropdown
                  key={"group" + open}
                  required={true}
                  isComboBox
                  label="Group"
                  name="Group"
                  selectedKey={controls.Group}
                  control={control}
                  onChange={(e, val) => {
                    if (val) {
                      handlecChangeDropdown("Group", val?.key);
                    } else {
                      handlecChangeDropdown("Group", null);
                    }
                  }}
                  options={getGroupListLu()}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Title"
                  name="Title"
                  control={control}
                  rules={{ required: "This field is required" }}
                  onChange={(e, val) =>
                    handlecChangeDropdown("Title", val?.key)
                  }
                />
              </div>
              <div className={styles.field}>
                <ControlledSpinButton
                  min={0}
                  labelPosition={0}
                  label="Sequence Order"
                  name="SequenceOrder"
                  control={control}
                  value={controls.SequenceOrder}
                  onChange={(e, val) => {
                    if (val) {
                      setValue("SequenceOrder", val);
                      handlecChangeDropdown("SequenceOrder", val);
                    } else {
                      setValue("SequenceOrder", 0);
                      handlecChangeDropdown("SequenceOrder", 0);
                    }
                  }}
                />
              </div>
              <div className={styles.field}>
                <ControlledDropdown
                  key={"Category" + open}
                  required={true}
                  label="Type"
                  name="Category"
                  control={control}
                  selectedKey={controls.Category}
                  onChange={(e, val) =>
                    handlecChangeDropdown("Category", val?.key)
                  }
                  options={[
                    {
                      key: "Widget",
                      text: "Widget",
                    },
                    { key: "Report", text: "Report" },
                  ]}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledDropdown
                  key={"Report  Type" + open}
                  required={true}
                  label="Sub  Type"
                  name="ReportType"
                  control={control}
                  selectedKey={controls.ReportType}
                  onChange={(e, val) =>
                    handlecChangeDropdown("ReportType", val?.key)
                  }
                  options={
                    //   [
                    //   { key: "Guest User Report", text: "Guest User Report" },
                    //   {
                    //     key: "Sharepoint site and storage",
                    //     text: "Sharepoint site and storage",
                    //   },
                    //   {
                    //     key: "SharePoint site permissions and export",
                    //     text: "SharePoint site permissions and export",
                    //   },
                    // ]

                    controls.Category === "Report"
                      ? [
                          { key: "Domo", text: "Domo" },
                          { key: "PBI", text: "Power BI" },
                        ]
                      : [
                          { key: "IDRB", text: "Invoice Summary" },
                          { key: "Link", text: "Link" },
                          { key: "M365", text: "Microsoft 365" },
                          { key: "OpsGenie", text: "OpsGenie" },
                        ]
                  }
                  rules={{ required: "This field is required" }}
                />
              </div>
              {controls?.ReportType == "Link" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="Url"
                    name="ReportUrl"
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("ReportUrl", val?.key)
                    }
                  />
                </div>
              )}
              {controls?.ReportType == "Domo" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="Domo Dashboard ID"
                    name="DomoDashboardID"
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("DomoDashboardID", val?.key)
                    }
                  />
                </div>
              )}
              {controls?.ReportType == "Domo" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="Domo Dashboard User"
                    name="DomoDashboardUser"
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("DomoDashboardUser", val?.key)
                    }
                  />
                </div>
              )}
              {controls?.ReportType == "Domo" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="Domo Filter"
                    name="DomoFilter"
                    row={3}
                    multiline
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("DomoFilter", val?.key)
                    }
                  />
                </div>
              )}
              {controls?.ReportType == "PBI" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="PBI Workspace Id"
                    name="PBIWorkspaceId"
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("PBIWorkspaceId", val?.key)
                    }
                  />
                </div>
              )}
              {controls?.ReportType == "PBI" && (
                <div className={styles.field}>
                  <ControlledTextField
                    required={true}
                    label="PBI Report Id"
                    name="PBIReportId"
                    control={control}
                    rules={{ required: "This field is required" }}
                    onChange={(e, val) =>
                      handlecChangeDropdown("PBIReportId", val?.key)
                    }
                  />
                </div>
              )}

              {controls?.ReportType !== "IDRB" && (
                <div className={styles.notePBI}>
                  <h6>
                    Note: Please make sure the PBI report is published to the
                    dedicated capacity workspace
                  </h6>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ActionButton disabled={disabled} type="submit">
            Submit
          </ActionButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateReport;
