import { useRoutes } from 'react-router';
import { routes } from '../routes';

const useAuthRoutes = () => {
  const routings = useRoutes(routes);

  return routings;
};

export default useAuthRoutes;
