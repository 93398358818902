import React, { useCallback, useState } from "react";
import { CommandBar, Icon, PrimaryButton } from "@fluentui/react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { useBoolean } from "@fluentui/react-hooks";
import WarningModal from "./WarningModal";
import { toast } from "react-toastify";

const Table = ({
  data,
  columns,
  setRowSelection,
  rowSelection,
  isLoadingList,
  handleToggle,
  onEditClick,
  isNextPrevDisabled,
  handleBack,
  isNextButtonDisabled,
  handleNext,
  handleDelete,
  type,
  showModalFilter,
  hideRowActions,
  hideCrudActions,
  hidePagination,
  hideSelection,
  isStickyHeaderFooter,
  maxHeight,
  columnVisibility,
  onSyncClick
}) => {
  const [disabled, setDisabled] = useState(false);
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal },
  ] = useBoolean(false);

  const onDelete = useCallback(() => {
    showDeleteModal();
  }, []);
  const table = useMaterialReactTable({
    columns,
    data: [...data],
    state: {
      isLoading: isLoadingList,
      rowSelection: rowSelection,
      columnVisibility: columnVisibility,
    },
    positionActionsColumn: "last",
    muiFilterTextFieldProps: (props) => {
      return { placeholder: `Search By ${props?.column?.columnDef?.header}` };
    },
    muiTableContainerProps: {
      sx: { maxHeight: maxHeight ? maxHeight : "auto" },
    },
    enableStickyHeader: isStickyHeaderFooter,
    enablePagination: false,
    // onGlobalFilterChange: (ev) => {
    //   setSearchData?.({ ...{ name: ev } });
    // },
    // initialState: { showGlobalFilter: true },
    renderTopToolbarCustomActions: () =>
      !hidePagination ? (
        <div className="d-flex">
          {" "}
          <PrimaryButton
            style={{ display: "flex", alignItems: "center", marginRight: 10 }}
            onClick={handleBack}
            disabled={isNextPrevDisabled}
          >
            <Icon
              iconName="Back"
              style={{
                marginRight: 10,
              }}
            />
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={handleNext}
            disabled={isNextButtonDisabled}
            style={{ display: "flex", alignItems: "center" }}
          >
            Next
            <Icon
              iconName="Forward"
              style={{
                marginLeft: 10,
              }}
            />
          </PrimaryButton>
        </div>
      ) : null,
    renderToolbarInternalActions: ({ table }) => (
      <>
        <CommandBar
          items={(!hideCrudActions
            ?(onSyncClick?[{
              key: "sync",
              text: `Sync`,
              iconProps: { iconName: "refresh" },
              onClick: () => onSyncClick(),
            }]:[]).concat ([
                {
                  key: "newItem",
                  text: "New",
                  cacheKey: "myCacheKey",
                  iconProps: { iconName: "Add" },
                  onClick: () => {
                    handleToggle?.();
                  },
                },
                {
                  key: "delete",
                  text: `Delete ${
                    table.getSelectedRowModel().rows?.length > 0
                      ? `(${table.getSelectedRowModel().rows?.length})`
                      : ""
                  } `,
                  disabled: !table.getSelectedRowModel().rows?.length > 0,
                  iconProps: { iconName: "Delete" },
                  onClick: () => onDelete?.(),
                },
              ])
            : []
          ).concat(
            showModalFilter
              ? [
                  {
                    key: "filter",
                    text: `Filter`,
                    iconProps: { iconName: "Filter" },
                    onClick: () => showModalFilter(true),
                  },
                ]
            : []
          )}
        />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
    enableRowActions: !hideRowActions,
    renderRowActions: ({ row }) => (
      <>
        <Icon
          iconName="Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            onEditClick?.(row);
          }}
        ></Icon>
      </>
    ),
    enableRowSelection: !hideSelection,
    getRowId: (row) => row.id,
    onRowSelectionChange: setRowSelection,
  });
  const onSuccessDelete = () => {
    setDisabled(false);
    changeCursor("");
    hideDeleteModal();
    setRowSelection({});
    toast.success("Successfully deleted");
  };
  const postDeleteRecords = useCallback(() => {
    setDisabled(true);
    changeCursor("wait");
    handleDelete(
      table.getSelectedRowModel().rows?.map((i) => i.original),
      onSuccessDelete
    );
    setTimeout(() => {
      setDisabled(false);
      changeCursor(" ");
    }, 1000);
  }, [table.getSelectedRowModel().rows?.length]);
  return (
    <div>
      <MaterialReactTable table={table} />
      <WarningModal
        title={`Are you sure to delete ${type}?`}
        description={`You are going to delete selected ${type}!`}
        isOpen={isDeleteModalOpen}
        onClose={hideDeleteModal}
        onSubmit={() => postDeleteRecords()}
        disabled={disabled}
      />
    </div>
  );
};

export default Table;
