import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { ManageUsersComp } from "../../components/Content/ManageUsersComp.js";
import CreateUser from "../../components/CreateUser";
import useManageUserAPI from "../../hooks/useManageUserAPI";
import { useManageUsersAPI } from "../../hooks/useManageUsersAPI";
import UserFilterModal from "../../components/ManageModals/UserFilterModal.jsx"



const CONTROL = {
  GroupLookupId: "",
  company: "",
  UserEmail:""
};

const SEARCH_DATA = {
  GroupLookupId: "",
  company: "",
  UserEmail:""
};

const ManageUsers = () => {
  const [showFilter, setShowFilter] = useState(false);
  const { userId } = useParams();
  const [userRecord, setUserRecord] = React.useState({});
  const { control, reset, setValue } = useForm({
    defaultValues:SEARCH_DATA
  });
  const {
    createMutation,
    updateMutation,
    deleteMutation,
  } = useManageUserAPI({
    id: userRecord.id,
  });

  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState([]);

  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const [controls, setControl] = useState({ ...CONTROL });

  const handlecChangeDropdown = (name, value) => {
    const copyControl = { ...controls };

    copyControl[name] = value;
    setControl((prev) => ({ ...prev, ...copyControl }));
  };

  const onSearch = () => {
    setPage(1);
    setSearchData({
      GroupLookupId: controls["GroupLookupId"],
      company: controls["company"],
      UserEmail:controls["UserEmail"]
    });
  };
  const {
    data: list,
    isLoadingList,
    changedCount,
    actionDisabled,
    refetchList,
    handleChangeValue,
    handleDeleteUsers,
    showModal,
    hideModal,
  } = useManageUsersAPI({
    page,
    enabled: true,
    sort,
    ...searchData,
  });
  const handleReset = () => {
    setControl({ ...CONTROL });
    setSearchData({ ...SEARCH_DATA });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleToggle = (isCreate) => {
    if (isCreate) setUserRecord({ ...{} });
    setOpenModal((prev) => !prev);
  };

  const handleCreate = () => {
    refetchList();
    toast.success(
      userRecord.id
        ? "User updated successfully"
        : "User created successfully"
    );
  };
  const onEditClick = (record) => {
    setUserRecord({ ...record.original });
    setOpenModal(true);
  };


  return (
    <div>
    <div style={{ display: "flex", alignContent: "center" }}>
      <h5 style={{ marginLeft: 8 }}>Manage Users</h5>
    </div>
      
      {/* <ToastContainer /> */}
      {openModal && (
        <CreateUser
          key={userId}
          open={openModal}
          handleClose={handleToggle}
          createMutation={createMutation}
          updateMutation={updateMutation}
          onSuccess={handleCreate}
          userId={userId}
          userRecord={userRecord}
        />
      )}
      {showFilter && (
              <UserFilterModal
              isModalOpen={showFilter}
              hideModal={()=>setShowFilter(false)}
              handlecChangeDropdown={handlecChangeDropdown}
              onSearch={onSearch}
              clear={handleReset}
              control={control}
              controls={controls}
            ></UserFilterModal>
      )}

      <ManageUsersComp
        list={list}
        isLoadingList={isLoadingList}
        changedCount={changedCount}
        handleToggle={handleToggle}
        page={page}
        setPage={setPage}
        control={control}
        controls={controls}
        reset={reset}
        setValue={setValue}
        handleChangeValue={handleChangeValue}
        actionDisabled={actionDisabled}
        handleDeleteUsers={handleDeleteUsers}
        handlecChangeDropdown={handlecChangeDropdown}
        clear={handleReset}
        handleCreate={handleCreate}
        isModalOpen={openModal}
        showModal={showModal}
        hideModal={hideModal}
        sort={sort}
        setSort={setSort}
        onSearch={onSearch}
        deleteMutation={deleteMutation}
        onEditClick={onEditClick}
        setShowFilter={setShowFilter}
      />
    </div>
  );
};

export default ManageUsers;
