import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { BASE_URL } from "../../services/api";
import { useSelector } from "react-redux";

const ManagePayment = () => {
  const stripePromise = loadStripe(
    `pk_test_51Ppn9D06RIiEcWG3C37xvy70ZNZhfJXNOeALlCUl5MjJ120wiOOPfXZF2tdagz9NQWZcG8dhACRYcvpKCYVLxmqH00oCfuFJJm`
  );

  //   const channel = new BroadcastChannel('app-data');
  // channel.addEventListener ('message', (event) => {
  //   const connection=event.data;
  //   connection
  //   .start()
  //   .then((data) => {
  //     connection.invoke(
  //       "InitiatePayment",
  //       JSON.parse(localStorage.getItem("InvoicePayInfo")).InvoiceId?.toString(),
  //       localStorage.getItem("SessionId")
  //     );
  //   })
  //   .catch(() => {});
  // });

  const { token } = useSelector((store) => store.auth);
  const fetchClientSecret = React.useCallback(() => {
    return fetch(`${BASE_URL}/api/Reports/InvPayment/CreateCheckoutSession`, {
      body: JSON.stringify({checkoutSessionList:JSON.parse(localStorage.getItem("InvoicePayInfo"))}),
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("SessionId", data.sessionId);
        return data.clientSecret;
      });
  }, []);
  const options = {
    fetchClientSecret: fetchClientSecret,
  };

  return (
    <>
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </>
  );
};

export default ManagePayment;
