import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Home = () => {
  return (
    <Wrapper>
      <img
        src={require('../assets/pbi.png')}
        width={200}
        height={200}
        alt="PBI icon"
      />
      <h3>RFA | Interactive Reports</h3>
    </Wrapper>
  );
};

export default Home;
