import React, { useState } from "react";
// import Modal from "../Modal";
import styles from "./style.module.scss";
import { ActionButton, Icon,Modal } from "@fluentui/react";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../ControlledTextField";
import { ControlledDropdown } from "../ControlledDropdown";
import { toast } from "react-toastify";
import { useCompaniesAPI } from "../../hooks/useCompaniesAPI";
const CONTROL = {
  MemberEmail: "",
      CompanyId: "",
      Role:""
};
const CreateUser = ({
  open,
  handleClose,
  onSuccess,
  createMutation,
  updateMutation,
  userRecord,
}) => {
  const SEARCH_DATA = {};

  const [page] = React.useState(1);
  const [companyId,setCompanyId]=useState("")
  const [isEditable, setIsEditable] = useState(false);
  const [sort] = React.useState([]);
  const [CwCompanyId, setCwCompanyId] = React.useState();
  const SEARCH_GROUP_DATA = {
    CompanyId: companyId,
  };
  const [controls, setControl] = useState({ ...CONTROL });
  const [searchData] = React.useState({ ...SEARCH_DATA });
  const [searchGroupData, setSearchGroupData] = React.useState({
    ...SEARCH_GROUP_DATA,
  });
  const { data: list } = useCompaniesAPI({
    page,
    enabled: true,
    sort,
    ...searchData,
  });
  // const { data: groupsList } = useCompanyGroupsAPI({
  //   page,
  //   enabled: true,
  //   sort,
  //   filter: { ...searchGroupData },
  // });
  // const { data: reportList } = useManageReportsAPI({
  //   page,
  //   enabled: true,
  //   sort,
  //   ...searchData,
  //   isForLookups: true,
  // });
  const [disabled, setDisabled] = useState(false);
  const changeCursor = (prevState) => {
    if (prevState === "wait") {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      MemberEmail: "",
      CompanyId: "",
      Role:""
    },
  });

  React.useEffect(() => {
    if (!userRecord.id) {
      setIsEditable(false);
      setControl({ ...CONTROL });
      Object.keys(controls).forEach((i) => {
        setValue(i, "");
      });
    }
  }, []);
  React.useEffect(() => {
    if (userRecord.id) {
      reset();
      setIsEditable(true);
      controls.CompanyId = list?.filter(
        (item) => item.groupId == userRecord.companyId
      )[0]?.groupId;
      controls.MemberEmail = userRecord.memberEmail;
      controls.MemberRole = userRecord.MemberRole;
      setControl({ ...controls });
      Object.keys(controls).forEach((i) => {
        setValue(i, controls[i]);
      });
    }
  }, [userRecord.id]);
  // }, [userRecord.id, groupsList?.length]);
  const onSubmit = (data) => {
 
    if(data.Email?.includes("rfa.")){
      return toast.error("Select Email other than rfa")
    }
    setDisabled(true);
    changeCursor("wait");
    const payload = {
      memberEmail: data.Email,
      cwCompanyId: CwCompanyId,
      memberRole:data.Role,
      companyId:data.CompanyId
    };
    if (!isEditable)
      createMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(error.message || "Something went wrong in user creation");
          setDisabled(false);
          changeCursor("");
        },
      });
    else {
      updateMutation.mutate(payload, {
        onSuccess: () => {
          onSuccess();
          setDisabled(false);
          changeCursor("");
          handleClose();
        },
        onError: (error) => {
          toast.error(error.message || "Something went wrong in user updation");
          setDisabled(false);
          changeCursor("");
        },
      });
    }
  };

  const handlecChangeDropdown = (key, val) => {
    const copyControl = { ...controls };

    copyControl[key] = val;
    setControl((prev) => ({ ...prev, ...copyControl }));
    setValue(key, val);
  };
  const getCompanyListLu = () => {
    return list?.map((item) => {
      return { ...{ key: item.groupId, text: item.title } };
    });
  };
  // const getGroupListLu = () => {
  //   return groupsList?.map((item) => {
  //     return { ...{ key: item.id, text: item.title } };
  //   });
  // };
  // const getReportListLu= () => {
  //   return reportList?.map((item) => {
  //     return { ...{ key: item.id, text: item.title } };
  //   });
  // };
  return (
    // <Modal open={open} onClose={handleClose}>
    <Modal
      isOpen={open}
      onDismiss={handleClose}
      dragOptions={{ dragHandleSelector: "form" }}
    >
      {/* <ToastContainer /> */}
      <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.header}>
     
          <h4 className={styles.title}>
            {isEditable ? "Edit" : "Add"} User{" "}
            {/* {isEditable ? <Icon iconName="Edit" /> : <Icon iconName="Add" />} */}
          </h4>
          <Icon
            iconName={"Cancel"}
            className={styles.close}
            onClick={handleClose}
          ></Icon>
        </div>

        <div className={styles.body}>
          <div className={styles.form}>
            <div className={styles.formGroup}>
              <h5 className={styles.groupTitle}>User Details</h5>
              <div className={styles.field}>
                <ControlledTextField
                  required={true}
                  label="Email"
                  name="Email"
                  control={control}
                  rules={{ required: "This field is required" }}
                />
              </div>
              <div className={styles.field}>
                <ControlledDropdown
                  key={"CompanyId" + open}
                  required={true}
                  label="Company"
                  name="CompanyId"
                  isComboBox
                  selectedKey={controls.CompanyId}
                  control={control}
                  onChange={(e, val) => {
                    if (val)
                     {
                      setValue("CompanyId", val.key);
                      setCwCompanyId(list?.filter(
                        (item) => item.groupId == val.key
                      )[0]?.cwCompanyID);
                      handlecChangeDropdown("CompanyId", val?.key);
                      searchGroupData.CompanyId = val?.key;
                      setSearchGroupData({ ...searchGroupData });
                    
                    }
                    else{
                      setValue("CompanyId", "");
                      setCompanyId("");
                      handlecChangeDropdown("CompanyId", "");
                      setCwCompanyId("");
                  
                    }
                  }}
                  options={getCompanyListLu()}
                  rules={{ required: "This field is required" }}
                />
              </div>
              {/* <div className={styles.field}>
                <ControlledDropdown
                  key={"group" + open}
                  selectedKey={controls.Group}
                  required={true}
                  isComboBox
                  label="Group"
                  name="Group"
                  control={control}
                  onChange={(e, val) => {
                    if (val
                    ) {
                      handlecChangeDropdown("Group", val?.key);
                    }
                    else{
                      handlecChangeDropdown("Group", null);
                    }
                  }}
                  options={getGroupListLu()}
                  rules={{ required: "This field is required" }}
                />
              </div> */}

              <div className={styles.field}>
                <ControlledDropdown
                  key={"Role" + open}
                  selectedKey={controls.Role}
                  required={true}
                  isComboBox
                  label="Role"
                  name="Role"
                  control={control}
                  onChange={(e, val) => {
                    if (val
                    ) {
                      handlecChangeDropdown("Role", val?.key);
                    }
                    else{
                      handlecChangeDropdown("Role", null);
                    }
                  }}
                  options={[{key:"Client-SiteContact",text:"Client-SiteContact"},{key:"Client-AccountingContact",text:"Client-AccountingContact"}]}
                  rules={{ required: "This field is required" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ActionButton disabled={disabled} type="submit">
            Submit
          </ActionButton>
        </div>
      </form>
    </Modal>
  );
};

export default CreateUser;
