import { createSlice } from '@reduxjs/toolkit';
import { roleKeys } from '../../constants/roles';
import { statusKeys } from '../../constants/statuses';

const initialState = {
  currentUser: {},
  status: statusKeys.NOT_CERTIFIED,
  role: roleKeys.DRAFTER,
  isOpenMenu: true
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    toggleMenu: (state) => {
      state.isOpenMenu = !state.isOpenMenu;
    }
  }
});

export const { setCurrentUser, setStatus, setRole, toggleMenu } =
  userSlice.actions;

export default userSlice.reducer;
