import React, { useEffect, useState } from "react";
import { Scheduler } from "@bitnoi.se/react-scheduler";
import "@bitnoi.se/react-scheduler/dist/style.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSchedular } from "../../hooks/useSchedular";
import { useLocation, useParams } from "react-router";
import Loader from "../Loader";
import styles from "./style.module.scss";
import { ControlledDropdown } from "../ControlledDropdown";
import { useForm } from "react-hook-form";
import { useSchedularGroups } from "../../hooks/useSchedularGroups";
export const StyledSchedulerFrame = styled.div`
  top: 8px;
  height: 84%;
  width: 100%;
  position: relative;
`;
export const ManageSchedular = () => {
  const { id } = useParams();
  const [zoomScale, setZoomScale] = useState(1);
  const [dateRange, setDateRange] = useState(1);
  const [group, setGroup] = useState(null);
  const { isOpenMenu } = useSelector((store) => store.userReducer);
  const location = useLocation();
  const [refreshObj, setRefreshObj] = useState({
    id: id,
    intervel: dateRange,
    intervalUnit: "weeks",
    pathname: location.pathname,
    group: null,
  });
  const { data: list, isLoading } = useSchedular({
    refreshObj: refreshObj,
    enable: !group,
  });
  const { data: depts, isLoadingGroups } = useSchedularGroups({
    refreshObj: { pathname: refreshObj.pathname, id: id },
  });
  const { control } = useForm({
    defaultValues: {
      DateRange: 1,
      Department: "",
    },
  });
  useEffect(() => {
    if (depts?.length && !group) {
      setGroup(depts[0]?.id);
    }
  }, [depts?.length]);
  useEffect(() => {
    setRefreshObj({
      id: id,
      intervel: dateRange,
      intervalUnit: dateRange == 3 ? "months" : "weeks",
      pathname: location.pathname,
      group: group || depts?.[0]?.id,
    });
  }, [location.pathname, id, dateRange, zoomScale, group, depts?.length]);
  const getDeptLu = () => {
    return (
      depts?.map((i) => {
        return { ...{ key: i.id, text: i.name } };
      }) || []
    );
  };

  return (
    <>
      <div className="d-flex p-1">
        <div className={styles.field}>
          <ControlledDropdown
            key={"daterange" + open}
            label="Date Range"
            name="DateRange"
            control={control}
            selectedKey={dateRange}
            onChange={(e, val) => {
              setDateRange(val.key);
              setZoomScale(val.key == 1 ? 1 : val.key > 2 ? 0 : 1);
            }}
            options={[
              {
                key: 1,
                text: "1 Week",
              },
              { key: 4, text: "4 Weeks" },
              { key: 3, text: "3 months" },
            ]}
            rules={{ required: "This field is required" }}
          />
        </div>
        <div className={`${styles.field} ${styles.leftMarging} `}>
          <ControlledDropdown
            key={"department" + open}
            label="Schedule"
            name="Department"
            control={control}
            selectedKey={group}
            onChange={(e, val) => {
              setGroup(val.key);
            }}
            options={getDeptLu()}
            rules={{ required: "This field is required" }}
          />
        </div>
      </div>
      {isLoading || isLoadingGroups ? (
        <Loader></Loader>
      ) : (
        <StyledSchedulerFrame isOpen={isOpenMenu}>
          <Scheduler
            isLoading={isLoading}
            data={
              list ?? []
              //   [
              //   {
              //     id: "070ac5b5-8369-4cd2-8ba2-0a209130cc60",
              //     label: {
              //       icon: "https://picsum.photos/24",
              //       title: "Joe Doe",
              //       subtitle: "Frontend Developer",
              //     },
              //     data:
              //      [
              //       {
              //         id: "8b71a8a5-33dd-4fc8-9caa-b4a584ba3762",
              //         startDate: new Date("2024-09-05T15:31:24.272Z"),
              //         endDate: new Date("2024-09-05T10:28:22.649Z"),
              //         occupancy: 3600,
              //         title: "Project A",
              //         subtitle: "Subtitle A",
              //         description: "array indexing Salad West Account",
              //         bgColor: "rgb(254,165,177)",
              //       },
              //       {
              //         id: "22fbe237-6344-4c8e-affb-64a1750f33bd",
              //         startDate: new Date("2024-09-07T15:31:24.272Z"),
              //         endDate: new Date("2024-09-07T10:28:22.649Z"),
              //         occupancy: 2852,
              //         title: "Project B",
              //         subtitle: "Subtitle B",
              //         description: "Tuna Home pascal IP drive",
              //         bgColor: "rgb(254,165,177)",
              //       },
              //     ],
              //   },
              // ]
            }
            onItemClick={() => {}}
            onFilterData={() => {}}
            onClearFilterData={() => {}}
            config={{
              filterButtonState: -1,
              zoom: zoomScale,
              lang: "en",
              showTooltip: false,
              showThemeToggle: false,
              maxRecordsPerPage: 10000,
            }}
          />
        </StyledSchedulerFrame>
      )}
    </>
  );
};
