import React from "react";
import { useSharePointReports } from "../../hooks/useSharePointReports.js";
import Table from "../Content/MaterialTable/index.jsx";
import moment from "moment";
import { Link } from "@fluentui/react/lib/Link";
import { useParams } from "react-router";
import Loader from "../Loader";

const ManageSharePointReports = () => {
  const { id } = useParams();
  const { data: list, isLoading } = useSharePointReports({ id: id });
  const getDynamicColumns = (item) => {
    let columns = item.columns?.map((col) => {
      return {
        ...{
          accessorKey: col.title,
          accessorFn: (row) => {
            return col.dataType === "DateTime" && row[col.title] ? (
              moment(row[col.title]).format("MM/DD/YYYY")
            ) : col.dataType?.includes("Number") ? (
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(row[col.title] || 0)
            ) : col.dataType === "HRef" ? (
              <Link
                key={row[col.title]}
                target="_blank"
                href="https://google.com"
              >
                {row[col.title]}
              </Link>
            ) : (
              row[col.title]
            );
          },
          header: col.title,
          grow: true,
          size: 150,
        },
      };
    });
    return columns;
  };
  const getDynamicData = (item) => {
    let data = [];
    item.rows?.map((row) => {
      let rowColumnObj = {};
      row.colData?.forEach((col, index) => {
        rowColumnObj[item.columns[index].title] = col.value;
      });
      data.push({ ...rowColumnObj });
    });
    return data;
  };
  const getColumnVisibility = (item) => {
    let columnVisibility = {};
    item.columns?.forEach((col) => {
      columnVisibility[col.title] = col.isVisible;
    });
    return columnVisibility;
  };

  return (
    <div>
      <div style={{ display: "flex", alignContent: "center" }}>
        <h5 style={{ marginLeft: 16, marginBottom: 8 }}>Share Point Reports</h5>
      </div>
      {isLoading? (
        <Loader></Loader>
      ) :(list?.map((item, index) => (
        <div style={{ marginTop: 8, marginLeft: 16 }} key={index
        }
        >
          <>
            <h6>{item.title}</h6>
            <Table
              columns={getDynamicColumns(item, index)}
              data={getDynamicData(item, index)}
              hideRowActions
              hideCrudActions
              hidePagination
              hideSelection
              isStickyHeaderFooter
              maxHeight={300}
              rowSelection={{}}
              isLoadingList={isLoading}
              columnVisibility={getColumnVisibility(item, index)}
              hideFooter
            />
          </>
        </div>
      )))}
    </div>
  );
};

export default ManageSharePointReports;
