import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams ,useLocation} from "react-router";
import { ManageInvoicesComp } from "../../components/Content/ManageInvoicesComp.js";
import InvoiceLineItems from "../../components/InvoiceLineItems";
import { useInvoicesAPI } from "../../hooks/useInvoicesAPI";
import { useInvoiceDisputeItems } from "../../hooks/useInvoiceDisputeItems.js";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../services/api.js";
import { Icon} from "@fluentui/react";
import { useQueryClient } from "react-query";
import PaymentModel from "../ManageModals/PaymentModel.js";
import { redirectUri } from "../../config/msal.js";


const CONTROL = {
  fromDate: "",
  toDate: "",
  fromAmount: "",
  toAmount: "",
  type: "",
  status:""
};

const SEARCH_DATA = {
  fromDate: "",
  toDate: "",
  fromAmount: "",
  toAmount: "",
  type: "",
  status:""
};

const ManageInvoices = () => {
  const location=useLocation();
  // const channel = new BroadcastChannel("my-channel");
  // let connection = new signalR.HubConnectionBuilder()
  //   .withUrl("http://localhost:6001/payment-hub", {
  //     skipNegotiation: true,
  //     transport: signalR.HttpTransportType.WebSockets,
  //   })

  //   .build();

  //   useEffect(()=>{
  //     if(connection._connectionState?.toLowerCase()=="disconnected"){
  //       channel.postMessage(JSON.stringify(connection),"payment");
  //     }
  //   },[connection._connectionState])
  // useEffect(() => {
  //   function checkUserData() {
  //     if (
  //       localStorage.getItem("SessionId") &&
  //       localStorage.getItem("InvoicePayInfo")
  //     ) {
  //       if(connection._connectionState!=="Connected" && connection._connectionState!=="Connecting"){
  //       connection
  //         .start()
  //         .then((data) => {
  //           connection.invoke(
  //             "InitiatePayment",
  //             JSON.parse(localStorage.getItem("InvoicePayInfo")).InvoiceId?.toString(),
  //             localStorage.getItem("SessionId")
  //           );
  //         })
  //         .catch(() => {});
  //       }
  //     }
  //   }

  //   window.addEventListener("storage", checkUserData);

  //   return () => {
  //     window.removeEventListener("storage", checkUserData);
  //   };
  // }, []);
  // connection.on("ReceivePaymentInfo", (message) => {
  //   if (message?.toLowerCase() == "payment succeeded") {
  //     toast.success(`Payment of ${message} successfully`);
  //     connection.stop();
  //   }
  // });
  const newClient = useQueryClient();
  const { token } = useSelector((store) => store.auth);
  const { id } = useParams();
  const [invoiceRecord, setInvoiceRecord] = React.useState({});
  const { currentUser: user } = useSelector((store) => store.userReducer);
  const { control, reset, setValue } = useForm({
    defaultValues: {
      fromDate: "",
      toDate: "",
      fromAmount: "",
      toAmount: "",
      type: "",
      status:""
    },
  });

  const [page, setPage] = React.useState(1);
  const [record]=useState({})
  const [sort, setSort] = React.useState([]);

  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const [controls, setControl] = useState({ ...CONTROL });

  const handlecChangeDropdown = (name, value) => {
    const copyControl = { ...controls };
    copyControl[name] = value;
    setControl((prev) => ({ ...prev, ...copyControl }));
  };


  const onSearch = () => {
    setPage(1);
    setSearchData({
      fromDate: controls["fromDate"],
      toDate: controls["toDate"],
      fromAmount: controls["fromAmount"],
      toAmount: controls["toAmount"],
      type: controls["type"],
      status:controls["status"]
    });
  };
  useEffect(() => {
    setControl({ ...CONTROL });
    setSearchData({...SEARCH_DATA});
  }, [location.pathname]);
  const {
    data: list,
    isLoadingList,
    changedCount,
    actionDisabled,
    handleChangeValue,
    showModal,
    hideModal,
  } = useInvoicesAPI({
    id,
    page,
    enabled: true,
    sort,
    searchData,
  });
  const {
    totalDispute,
    totalLineItems,
    isLoading,
    undisputedBalance,
    balance,
    ccy,
    disputeObj
  } = useInvoiceDisputeItems({
    id,
  });
  const handleReset = () => {
    setControl({ ...CONTROL });
    setSearchData({ ...SEARCH_DATA });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);
  const handleToggle = (isCreate) => {
    if (isCreate) setInvoiceRecord({ ...{} });
    setOpenModal((prev) => !prev);
  };

  const onEditClick = (record) => {
    setInvoiceRecord({ ...record });
    setOpenModal(true);
  };
  const onDownLoadClick = async (record) => {
    let result = await fetch(
      `${BASE_URL}/api/Reports/${id}/Invoices/${record.id}/DownloadInvoice`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    var link = document.createElement("a");
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    link.download = `Invoice_${record.type?record.id:record.invoiceNumber}`;
    link.href = href;
    link.click();
  };

  const onPaymentClick = async (record) => {
    // setRecord(record)
    // setOpenPaymentModal(true)

    localStorage.setItem("InvoicePayInfo", JSON.stringify({
      CCY: record.ccy,
      InvAmount: record.amount_Undispute,
      InvoiceNum: record.invoiceNumber,
      InvoiceId:record.id
    }));
    window.open(`${redirectUri}/invoicepayment/${record.id}`, "_blank");
  };
  const handleSuccess = (inv) => {
    if (inv) {
      handleChangeValue(inv.id, "amount_Dispute", inv.amount_Dispute);
    }
  };

  return (
    <div>
      <div style={{display:"flex",alignContent:"center"}}>
      <h5 style={{ marginLeft: 16, marginBottom: -8 }}>Invoices {user.companyName?`(${user.companyName})`:""}</h5>
      {Object.keys(disputeObj || {}).length > 0 && (
            <Icon
              onClick={() => {
                newClient.invalidateQueries({
                  queryKey: ["Dispute"],
                  refetchType: "all",
                });
              }}
              iconName="refresh"
              style={{
                marginLeft: 20,
                marginTop: 4,
                cursor: "pointer",
              }}
            />
          )}
          {/* {isLoading && (
            <Spinner
              size={SpinnerSize.large}
              style={{
                marginLeft: 20,
                marginTop: 4,
              }}
            />
          )} */}
          </div>
      {/* <ToastContainer /> */}
      {openModal && (
        <InvoiceLineItems
          open={openModal}
          handleClose={handleToggle}
          invoiceRecord={invoiceRecord}
          setInvoiceRecord={setInvoiceRecord}
          handleSuccess={handleSuccess}
          onDownLoadClick={onDownLoadClick}
          onPaymentClick={onPaymentClick}
        />
      )}
            {openPaymentModal  && (
        <PaymentModel
        isModalOpen={openPaymentModal}
        hideModal={()=>{
          setOpenPaymentModal(false)
        }}
        record={record}
        token={token}
        />
      )}

      <ManageInvoicesComp
        list={list}
        totalDispute={totalDispute}
        totalLineItems={totalLineItems}
        undisputedBalance={undisputedBalance}
        balance={balance}
        ccy={ccy}
        isLoadingList={isLoadingList}
         isLoading={isLoading}
        changedCount={changedCount}
        handleToggle={handleToggle}
        page={page}
        setPage={setPage}
        control={control}
        controls={controls}
        reset={reset}
        setValue={setValue}
        handleChangeValue={handleChangeValue}
        actionDisabled={actionDisabled}
        handlecChangeDropdown={handlecChangeDropdown}
        clear={handleReset}
        isModalOpen={openModal}
        showModal={showModal}
        hideModal={hideModal}
        sort={sort}
        setSort={setSort}
        onSearch={onSearch}
        onEditClick={onEditClick}
        onDownLoadClick={onDownLoadClick}
    disputeObj
={disputeObj}
status={searchData.status}
      />
    </div>
  );
};

export default ManageInvoices;
