import classNames from "classnames";
import { motion } from "framer-motion";
import { Icon } from "@fluentui/react";
import React from "react";
import styles from "./style.module.scss";

const variants = {
  hidden: {
    x: "100vw",
  },
  animate: { x: "-100vw" },
};

const Modal = ({ open, children, onClose ,isCustomize}) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.opened]: open })}>
      <motion.div
        animate={open ? "animate" : "hidden"}
        variants={variants}
        className={isCustomize?styles.cmodal:styles.modal}
        transition={{
          duration: 0.7,
        }}
      >
        <Icon
          iconName={isCustomize?"Cancel":"Forward"}
          className={styles.close}
          onClick={onClose}
        ></Icon>

        {children}
      </motion.div>
    </div>
  );
};

export default Modal;
