import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AuthenticatedProvider from './components/AuthenticatedProvider';
import useAuthRoutes from './hooks/useAuthRoutes';

import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';

export default function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const routings = useAuthRoutes();

  return (
    <>
    <ToastContainer style={{zIndex:99999999999,position:"top-right"}}/>
    <AuthenticatedProvider
      isAuthenticated={isAuthenticated}
      instance={instance}
      accounts={accounts}
    >
      <> {routings}</>
    </AuthenticatedProvider>
    </>
  );
}
