import {
  FontWeights,
  getTheme,
  IconButton,
  mergeStyleSets,
  Modal,
  PrimaryButton
} from '@fluentui/react';
import React from 'react';

const WarningModal = ({ title, description, isOpen, onClose, onSubmit, disabled }) => {
  const theme = getTheme();

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: 400
    },
    header: [
      {
        flex: '1 1 auto',
        borderTop: `4px solid #652240`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px'
      }
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 }
      }
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 15px'
    },
    button: {
      width: '49%'
    },
    cancel: {
      width: '49%',
      backgroundColor: 'transparent',
      color: '65224' // '#4f5291'
    }
  });

  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px'
    },
    rootHovered: {
      color: theme.palette.neutralDark
    }
  };

  return (
    <Modal
      titleAriaId="Warning"
      isOpen={isOpen}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id="Warning">{title}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={onClose}
        />
      </div>
      <div className={contentStyles.body}>
        <p>{description}</p>
      </div>

      <div className={contentStyles.footer}>
        <PrimaryButton className={contentStyles.cancel} onClick={onClose}>
          Cancel
        </PrimaryButton>
        <PrimaryButton disabled={disabled} className={contentStyles.button} onClick={onSubmit}>
          Confirm
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default WarningModal;
