import { useEffect, useRef, useState } from "react";

const REFRESH_THRESHOLD = 60; // 1 minutes in seconds
const TOKEN_CHECK_INTERVAL = 600000; // 10 minute in milliseconds

export const useTokenCheckExpirationTime = (expiryTime, token) => {
  const interval = useRef(null);
  const [isTokenGoingToExpire, setIsTokenGoingToExpire] = useState(false);
  useEffect(() => {
    const checkTokenExpiry = () => {
      if (token) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiry = expiryTime - currentTime;
        if (timeUntilExpiry <= REFRESH_THRESHOLD) {
          setIsTokenGoingToExpire(true);
        }
      }
    };
    interval.current = setInterval(checkTokenExpiry, TOKEN_CHECK_INTERVAL);
    checkTokenExpiry();
    return () => clearInterval(interval.current);
  }, [token, expiryTime]);
  return { isTokenGoingToExpire: isTokenGoingToExpire };
};
