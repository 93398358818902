import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { customAPI } from "../services/api";

let totalCount = 0;

const getUserItem = (id) =>
  customAPI.get("api/GroupUsers/" + id).then((res) => {
    return res;
  });

const createUser = (data, token) => {
  return customAPI(token).post("api/ClientUser", data);
};

const editUser = (id, data, token) => {
  return customAPI(token).put("api/GroupUsers/" + id, data);
};
const deleteUser = (id, data, token) => {
  return customAPI(token).delete("api/GroupUsers/" + id);
};

const useManageUserAPI = ({ id = null }) => {
  const { token } = useSelector((store) => store.auth);

  const { data: detail } = useQuery(
    `GET-USER-ITEM-${id}`,
    () => getUserItem(id),
    {
      enabled: !!id,
    }
  );

  const createMutation = useMutation((data) => createUser(data, token), {
    onError: (error) => {
      if (error.response.status == 409) {
        error.message = "Duplicate User entry exists";
        return error;
      } else {
        error.message = "Something went wrong";
        return error;
      }
    },
  });

  const updateMutation = useMutation((data) => editUser(id, data, token));
  const deleteMutation = useMutation((data) => deleteUser(id, data, token));

  return {
    totalCount: totalCount,
    detail,
    deleteMutation,
    createMutation,
    updateMutation,
  };
};

export default useManageUserAPI;
