import { useMemo } from 'react';
import { customAPI } from '../services/api';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const getGroups = (token) =>
  customAPI(token)
    .get('api/Navigations')
    .then((res) => res?.data);

const normalizer = (items) => {
  const newItems = items?.map((item,index) => ({
    name: item?.companyName,
    // ur: item?.companyName,
    expandAriaLabel: 'Show more ' + item?.companyName,
    collapseByDefault: false,
    links: item?.groups?.map((group) => ({
      name: group?.groupName,
      // ur: group?.groupName,
      expandAriaLabel: 'Show more ' + group?.groupName,
      collapseByDefault: false,
      links: group?.reports?.map((report) => ({
        name: report?.name,
        key: report?.id,
        comapnyIndex:index,
        groupName:group?.groupName,
        companyName:item?.companyName,
        reportType: report.reportType,
        category:report.category,
        // url: `/report/${report?.id}`
        id: report?.id
      }))
    }))
  }));

  return newItems;
};

export const useGroupsAPI = () => {
  const { token } = useSelector((store) => store.auth);

  const { data, isLoading, refetch } = useQuery('GET-GROUPS', () =>
    getGroups(token)
  );

  const normlizedData = useMemo(() => normalizer(data), [data]);

  return { data: normlizedData, isLoading, refetch };
};
