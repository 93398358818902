import { useMemo } from "react";
import { customAPI } from "../services/api";
import { useQuery, useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
const size = 20;

const getMangeCompanies = (token, page, sort, filter) =>
  customAPI(token)
    .get("api/Company", {
      params: {
        size,
        page,
        filter: filter,
        orderBy:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `Fields/${k.subKey}`)}`
            : undefined,
        sort:
          sort?.length && sort?.filter((i) => i.value)?.length
            ? `${sort.filter((i) => i.value).map((k) => `${k.value}`)}`
            : undefined,
      },
    })
    .then((res) => res?.data?.data);

const normalizer = (items) => {
  const newItems = items?.map((item) => ({
    id: item?.id,
    companyName: item?.name,
    cwCompanyID: item?.cwCompanyID,
    createdUser: item?.createdUser,
    modifiedUser: item?.modifiedUser,
    domain: item?.domain,
  }));

  return newItems;
};
const deleteCompany = (data, token) => {
  return customAPI(token).delete("api/Company/" + data.id);
};

export const useManageCompaniesAPI = ({ page = 1, sort, name}) => {
  const { token } = useSelector((store) => store.auth);
  const location=useLocation();

  const deleteMutation = useMutation((data) => deleteCompany(data, token));
  const handleDeleteCompanies = async (list, onSuccess) => {
    try {
      list.forEach((company) => {
        deleteMutation.mutate(company, {
          onSuccess: () => {
            refetch();
            onSuccess();
          },
          onError: (error) => {
            if (error.response.status == 409) {
              toast.error(error.response.data.message);
              return error;
            } else {
              error.message = "Something went wrong";
              return error;
            }
          },
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
  let filter = ``;
  if (name) {
    filter = `Fields/Name eq ${name}`;
  }
  const { data, isLoading, refetch } = useQuery(
    [`GET-s-${name}-${page}-${sort?.map((i) => `${i.key}-${i.value}`)}`, location?.pathname],
    () => getMangeCompanies(token, page, sort, filter)
  );
  const normlizedData = useMemo(() => normalizer(data), [data]);

  return {
    data: normlizedData,
    isLoadingList: isLoading,
    refetchList: refetch,
    handleDeleteCompanies,
  };
};
