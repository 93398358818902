import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  PrimaryButton,
  CommandBar,
  Icon,
  Label,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import InvoiceFilterModal from "../../ManageModals/InvoiceFilterModal";
import { invoiceSummaryColumns } from "./utils";
import { useLocation } from "react-router";
import moment from "moment";

const ActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20;
  position: sticky;
  top: 64px;
  z-index: 9;
  background-color: #fff;
`;

const LeftAction = styled.div`
  display: flex;
`;

const RightAction = styled.div`
  display: flex;
  align-items: center;

  & button:last-child {
    margin: 0 10px;
  }
  .success-btn {
    background-color: green;
    border: green;
    color: #fff;
  }
`;
const summaryRecords = [
  {
    heading: "Current",
    balance: 0,
    disputeAmount: 0,
    unDisputeBalance: 0,
    disputedItemsCount: 0,
    currency: "",
    key: [
      "currentBalance",
      "currentDisputeAmount",
      "currentUnDisputeBalance",
      "currentDisputeItemCount",
    ],
  },
  {
    heading: "Over 30 days",
    balance: 0,
    disputeAmount: 0,
    unDisputeBalance: 0,
    disputedItemsCount: 0,
    currency: "",
    key: [
      "balance30",
      "disputeAmount30",
      "unDisputeBalance30",
      "disputeItemCount30",
    ],
  },
  {
    heading: "Over 60 days",
    balance: 0,
    disputeAmount: 0,
    unDisputeBalance: 0,
    disputedItemsCount: 0,
    key: [
      "balance60",
      "disputeAmount60",
      "unDisputeBalance60",
      "disputeItemCount60",
    ],
    currency: "",
  },
  {
    heading: "Over 90 days",
    balance: 0,
    disputeAmount: 0,
    unDisputeBalance: 0,
    disputedItemsCount: 0,
    key: [
      "balance90",
      "disputeAmount90",
      "unDisputeBalance90",
      "disputeItemCount90",
    ],
    currency: "",
  },
  {
    heading: "Total",
    balance: 0,
    disputeAmount: 0,
    unDisputeBalance: 0,
    disputedItemsCount: 0,
    currency: "",
    key: ["balance", "disputeAmount", "unDisputeBalance", "disputeItemCount"],
  },
];
const ActionsHeader = ({
  control,
  controls,
  onSearch,
  onReset,
  isNextPrevDisabled,
  handleBack,
  isNextButtonDisabled,
  handleNext,
  selectionDetails,
  handlecChangeDropdown,
  type,
  totalDispute,
  isUpdateButton,
  updateDisputeNotes,
  totalLineItems,
  undisputedBalance,
  balance,
  ccy,
  isLoading,
  disputeObj,
  selectedItems
}) => {
  const location = useLocation();
  const [summary, setSummary] = useState([...summaryRecords]);
  const [summaryType] = useState("Summary");
  const [
    isModalOpenFilter,
    { setTrue: showModalFilter, setFalse: hideModalFilter },
  ] = useBoolean(false);

  const buttons = useMemo(() => {
    return [
      // {
      //   key: "pay",
      //   text:
      //     selectionDetails > 0
      //       ? `Pay ( ${new Intl.NumberFormat("en-US", {
      //           maximumFractionDigits: 2,
      //           minimumFractionDigits: 2,
      //         }).format(
      //           selectedItems
      //             ?.map((i) => i.amount_Undispute)
      //             .reduce((acc, cur) => acc + cur, 0)
      //         )})`
      //       : `Pay`,
      //   iconProps: { iconName: "AllCurrency" },
      //   onClick: () => onPaymentClick(),
      // },
      {
        key: "filter",
        text: `Filter`,
        iconProps: { iconName: "Filter" },
        onClick: () => showModalFilter(),
      },
    ];
  }, [selectionDetails, selectedItems, selectedItems?.length]);
  const getSummarySum = (array, detail) => {
    array.forEach((rec) => {
      detail[`balance`] += rec[`balance`] ?? 0;
      detail[`disputeAmount`] += rec[`disputeAmount`] ?? 0;
      detail[`unDisputeBalance`] += rec[`unDisputeBalance`] ?? 0;
      detail[`disputedItemsCount`] += rec[`disputedItemsCount`] ?? 0;
    });
    return detail;
  };
  const runSummary = (disputeObj) => {
    if (Object.keys(disputeObj || {}).length) {
      let details = JSON.parse(JSON.stringify(summaryRecords));
      details.forEach((detail) => {
        if (detail.heading != "Total") {
          detail[`balance`] = disputeObj[detail.key[0]];
          detail[`disputeAmount`] = disputeObj[detail.key[1]];
          detail[`unDisputeBalance`] = disputeObj[detail.key[2]];
          detail[`disputedItemsCount`] = disputeObj[detail.key[3]];
          detail[`currency`] = disputeObj["ccy"];
        } else {
          let newArray = details.filter((i) => i.heading != "Total");
          detail = getSummarySum(newArray, details[details.length - 1]);
        }
      });
      setSummary([...details]);
    }
  };
  useEffect(() => {
    if (!isLoading) {
      runSummary(disputeObj);
    }
  }, [Object.keys(disputeObj || {})?.length, disputeObj, isLoading]);
  useEffect(() => {
    setSummary(JSON.parse(JSON.stringify(summaryRecords)));
    if (!isLoading)
      setTimeout(() => {
        runSummary(disputeObj);
      }, 100);
  }, [location.pathname]);
  const handleReset = () => {
    onReset();
    hideModalFilter();
  };
  return (
    <>
      <InvoiceFilterModal
        isModalOpen={isModalOpenFilter}
        hideModal={hideModalFilter}
        handlecChangeDropdown={handlecChangeDropdown}
        onSearch={onSearch}
        clear={handleReset}
        control={control}
        controls={controls}
        type={type}
      ></InvoiceFilterModal>
      {summaryType === "Summary" && (
        <div
          className="summary-table summary-relative"
          style={{
            maxHeight: () => {
              return window.document.body.clientHeight * 0.4;
            },
          }}
        >
          <table className="table table-striped">
            <thead>
              <tr className="table-primary">
                {invoiceSummaryColumns({}).map((record,ind) => {
                  return <th key={ind}>{record.name}</th>;
                })}
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              {summary.map((record,index) => {
                return (
                  <tr key={index}>
                    <td>
                      <b>{record.heading}</b>
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(record.balance) || 0}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(record.disputeAmount) || 0}
                    </td>
                    <td>
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(record.unDisputeBalance) || 0}
                    </td>
                    <td>{record.disputedItemsCount}</td>
                    <td>{record.currency}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading && (
            <Spinner
              size={SpinnerSize.large}
              className="absolute-spinner"
              style={{
                marginLeft: 20,
                marginTop: 4,
              }}
            />
          )}
        </div>
      )}
      <div
        className="summary-table summary-relative"
        style={{
          maxHeight: () => {
            return window.document.body.clientHeight * 0.4;
          },
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr className="table-primary">
              <th>Last Payment Date</th>
              <th>Last Payment Amount</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {disputeObj?.paymentDetails?.lastPaymentDate
                  ? moment(disputeObj?.paymentDetails?.lastPaymentDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
              </td>
              <td>
                {new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(disputeObj?.paymentDetails?.lastPaymentAmount || 0) ||
                  0}
                  {disputeObj?.paymentDetails?.paymentCount&& <>{` - ${disputeObj?.paymentDetails?.paymentCount} Payment(s)`}</>}
              </td>
              <td>{disputeObj?.ccy}</td>
            </tr>
          </tbody>
        </table>
        {isLoading && (
        <Spinner
          size={SpinnerSize.small}
          className="absolute-spinner"
          style={{
            marginLeft: 20,
            marginTop: 4,
          }}
        />
      )}
      </div>

      <ActionBox>
        <LeftAction>
          <PrimaryButton
            style={{ display: "flex", alignItems: "center", marginRight: 10 }}
            onClick={handleBack}
            disabled={isNextPrevDisabled}
          >
            <Icon
              iconName="Back"
              style={{
                marginRight: 10,
              }}
            />
            Previous
          </PrimaryButton>

          <PrimaryButton
            onClick={handleNext}
            disabled={isNextButtonDisabled}
            style={{ display: "flex", alignItems: "center" }}
          >
            Next
            <Icon
              iconName="Forward"
              style={{
                marginLeft: 10,
              }}
            />
          </PrimaryButton>
          {summaryType !== "Summary" && (
            <>
              {!type && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label> Totals - </Label>
                </div>
              )}
              {!type && totalDispute && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label>
                    {" "}
                    {"("}Dispute :{" "}
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(totalDispute) || 0}
                    ,
                  </Label>
                </div>
              )}
              {!type && totalLineItems && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label> Items : {totalLineItems || 0},</Label>
                </div>
              )}
              {!type && balance && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label>
                    {" "}
                    Total :{" "}
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(balance) || 0}
                    ,
                  </Label>
                </div>
              )}
              {!type && undisputedBalance && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label>
                    {" "}
                    Unpaid Balance :{" "}
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(undisputedBalance) || 0}
                  </Label>
                </div>
              )}
              {!type && ccy && (
                <div style={{ marginLeft: 16, fontWeight: 600 }}>
                  <Label>
                    {" "}
                    CCY : {ccy}
                    {")"}
                  </Label>
                </div>
              )}
            </>
          )}
          {/* {!type &&
            (totalLineItems ||
              totalDispute ||
              balance ||
              undisputedBalance) && ( */}
          {/* {!type && Object.keys(disputeObj || {}).length > 0 && (
            <Icon
              onClick={() => {
                newClient.invalidateQueries({
                  queryKey: ["Dispute"],
                  refetchType: "all",
                });
              }}
              iconName="refresh"
              style={{
                marginLeft: 20,
                marginTop: 4,
                cursor: "pointer",
              }}
            />
          )}
          {!type && isLoading && (
            <Spinner
              size={SpinnerSize.large}
              style={{
                marginLeft: 20,
                marginTop: 4,
              }}
            />
          )} */}
        </LeftAction>
        {type && isUpdateButton && (
          <RightAction>
            <CommandBar
              items={[
                {
                  key: "update",
                  text: `Update`,
                  iconProps: { iconName: "edit" },
                  onClick: () => {
                    updateDisputeNotes?.();
                  },
                },
              ]}
              ariaLabel="Inbox actions"
              primaryGroupAriaLabel="Email actions"
              farItemsGroupAriaLabel="More actions"
            />
          </RightAction>
        )}
        {!type && (
          <RightAction>
            <CommandBar
              items={buttons}
              ariaLabel="Inbox actions"
              primaryGroupAriaLabel="Email actions"
              farItemsGroupAriaLabel="More actions"
            />
          </RightAction>
        )}
      </ActionBox>
    </>
  );
};

export default ActionsHeader;
