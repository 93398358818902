import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import CreateGroup from "../CreateGroup/index.jsx";
import { useManageGroupsAPI } from "../../hooks/useManageGroupsAPI.js";
import useManageGroup from "../../hooks/useManageGroup.js";
import { toast } from "react-toastify";
import Table from "../Content/MaterialTable";
import GroupFilterModal from "../ManageModals/GroupFilterModal.js";
import { useForm } from "react-hook-form";

const CONTROL = {
  company: "",
  securityGroup: "",
};

const SEARCH_DATA = {
  company: "",
  securityGroup:""
};

const ManageGroups = () => {
  const location = useLocation();
  const { id } = useParams();
  const [groupRecord, setGroupRecord] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [sort] = React.useState([]);
  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const [controls, setControl] = useState({ ...CONTROL });
  const { control, setValue} = useForm({
    defaultValues: {
      company: "",
  securityGroup:""
    },
  });
  const {
    data: list,
    isLoadingList,
    refetchList,
    handleDeleteGroups,
  } = useManageGroupsAPI({
    id,
    page,
    enabled: true,
    sort,
    ...searchData,
  });
  const onSearch = () => {
    setPage(1);
    setSearchData({
      company: controls["company"],
      securityGroup: controls["securityGroup"],
    });
  };
  useEffect(() => {
    setControl({ ...CONTROL });
    setSearchData({ ...SEARCH_DATA });
  }, [location.pathname]);
  const { createMutation, updateMutation } = useManageGroup({
    id: groupRecord.id,
  });

  const [openModal, setOpenModal] = React.useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const handleToggle = (isCreate) => {
    if (isCreate) setGroupRecord({ ...{} });
    setOpenModal((prev) => !prev);
  };

  const onEditClick = (record) => {
    setGroupRecord({ ...record.original });
    setOpenModal(true);
  };
  const handleCreate = () => {
    setOpenModal(false);
    refetchList();
    toast.success(
      groupRecord.id
        ? "Group updated successfully"
        : "Group created successfully"
    );
  };

  const groupsList = useMemo(() => {
    return list ?? [];
  }, [list]);
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "groupName",
      //   header: "Group Name",
      //   size: 150,
      // },
      {
        accessorKey: "displayName",
        header: "Group Display Name",
        size: 150,
      },
      {
        accessorKey: "securityGroup",
        header: "Security Group",
        size: 150,
      },
      {
        accessorKey: "sequence",
        header: "Seq",
        size: 150,
      },
      {
        accessorKey: "companyName.name",
        header: "Company",
        size: 150,
      },
    ],
    []
  );
  const isNextButtonDisabled = list?.length === 0 || list?.length % 20 !== 0;

  const isPrevButtonDisabled = page === 1;

  const handleBack = () => {
    setRowSelection({});
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setRowSelection({});
    if (isNextButtonDisabled) return;

    setPage((prev) => prev + 1);
  };
  const handlecChangeDropdown = (key, val) => {
    const copyControl = { ...controls };

    copyControl[key] = val;
    setControl((prev) => ({ ...prev, ...copyControl }));
    setValue(key, val);
  };
  const handleReset = () => {
    setControl({ ...CONTROL });
    setSearchData({ ...SEARCH_DATA });
  };
  return (
    <div>
      <div style={{ display: "flex", alignContent: "center" }}>
        <h5 style={{ marginLeft: 8 }}>Groups</h5>
      </div>
      {openModal && (
        <CreateGroup
          key={id}
          open={openModal}
          handleClose={handleToggle}
          createMutation={createMutation}
          updateMutation={updateMutation}
          onSuccess={handleCreate}
          userId={id}
          groupRecord={groupRecord}
        />
      )}
      {showFilter && (
        <GroupFilterModal
          isModalOpen={showFilter}
          hideModal={() => setShowFilter(false)}
          handlecChangeDropdown={handlecChangeDropdown}
          onSearch={onSearch}
          clear={handleReset}
          control={control}
          controls={controls}
        />
      )}
      {location.pathname?.includes("managegroups") && (
        <Table
          columns={columns}
          setRowSelection={setRowSelection}
          rowSelection={rowSelection}
          handleToggle={handleToggle}
          onEditClick={onEditClick}
          isLoadingList={isLoadingList}
          data={groupsList}
          handleBack={handleBack}
          handleNext={handleNext}
          isNextButtonDisabled={isNextButtonDisabled}
          isNextPrevDisabled={isPrevButtonDisabled}
          handleDelete={handleDeleteGroups}
          type={"groups"}
          setSearchData={setSearchData}
          showModalFilter={setShowFilter}
        />
      )}
    </div>
  );
};

export default ManageGroups;
