import axios from 'axios';

// export const BASE_URL = process.env.REACT_BASE_URL;
export const BASE_URL = 'https://reporting-api.rfa.com';

// export const BASE_URL = 'https://rfa-devreports-api.azurewebsites.net/';



export const customAPI = (token) =>
  axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: 'Bearer ' + token
    }
  });