import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { msalInstance } from '../..';
import { loginRequest } from '../../config/msal';
import { setExpiryTime, setToken } from "../../redux/authSlice";
import { setCurrentUser } from '../../redux/userSlice';
import { useProfile } from "../../hooks/useProfile";
import { useSelector } from "react-redux";
import { useTokenCheckExpirationTime } from "../../hooks/useTokenCheckExpirationTime";

const AuthenticatedProvider = ({ children, accounts, isAuthenticated }) => {
  const { token, expiryTime } = useSelector((store) => store.auth);
  const { isTokenGoingToExpire } = useTokenCheckExpirationTime(
    expiryTime,
    token
  );
  const { currentUser } = useSelector((store) => store.userReducer);
  const { data: profile } = useProfile({ token });
  const dispatch = useDispatch();
  useEffect(() => {
    if (isTokenGoingToExpire && isAuthenticated) {
      getToken();
      dispatch(setCurrentUser({}));
    }
  }, [isTokenGoingToExpire, isAuthenticated]);
  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(currentUser || {},"isAdmin") && profile) {
      dispatch(setCurrentUser({ ...currentUser, isAdmin: profile.isAdmin ,isExecutive:profile.isExecutive}));
    }
  }, [profile, Object.keys(currentUser || {}).length]);
  const getAuth = async () => {
    msalInstance
      .handleRedirectPromise()
      .then(() => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          msalInstance.loginRedirect(loginRequest);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogout = () => {
    msalInstance.logout({
      postLogoutRedirectUri: '/'
    });
  };

  const getToken = async () => {
    if(!accounts.length) return
    try {
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
        .then((response) => {
          dispatch(setCurrentUser({ ...response?.account }));
          dispatch(setToken(response?.accessToken));
          if (!expiryTime || expiryTime - Math.floor(Date.now() / 1000) <= 60)
            dispatch(setExpiryTime(Math.floor((Date.now() + 3600000) / 1000)));
        });
    } catch (err) {
      console.log(err);
      handleLogout();
    }
  };

  useEffect(() => {
    if(isAuthenticated){    getToken();
      dispatch(setCurrentUser({}));}

    if(!isAuthenticated){
      getAuth()
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <></>;

  return <>{children}</>;
};

export default AuthenticatedProvider;
