import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import Loader from '../components/Loader';



const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Global = ({ data }) => {
    const { id, type } = useParams();
    const [showDomo, setShowDomo] = useState(false)


    useEffect(() => {
        // setShowDomo(false)
        setTimeout(() => {
            setShowDomo(true)
        }, 1300);
    }, [id, type])


    useEffect(() => {
        let tag = document.getElementById("iframe_report1")

        if (!tag.contentWindow) return
        else {
            if (!tag.contentWindow.document) return
            // if(! tag.contentWindow.document.getElementById("submitbtn")) return
            //tag.contentWindow.document.getElementById("submitbtn").click();

            setTimeout(() => {
                tag.contentWindow.document.getElementById("submitbtn").click();
            }, 10);
        }
    }, [document.getElementById("iframe_report1"), id, type])


    const html = `
  <body >
  <iframe style='opacity:${showDomo ? 1 : 0};'  name="my_iframe" id = "iframe_report1"
      srcdoc="<form id='form' action=${data.url} method='post' > <input type='hidden' name='embedToken' value=${data.embedToken}> <button style='opacity:0;''   id='submitbtn' type='submit'></button></form> <script>document.getElementById('form').submit</script>"
      src="https://www.birthdaycalculatorbydate.com/" width="800px" height="200px"></iframe>
  <script>document.getElementById('form').submit</script>
</body>
`

    console.log("showDomo", showDomo)
    return (
        <Wrapper>
            <div id="loader_id1" style={{ display : showDomo ? "none" : 'initial' }}>
                <Loader />
            </div>

            <div dangerouslySetInnerHTML={{ __html: html }} ></div>
        </Wrapper>
    );
};

export default Global;
