import React from "react";
import ManageSharePointReport from "../../components/ManageSharePointReports";
import { useParams } from "react-router";
import ManageInvoices from "../../components/ManageInvoices";
import ManageLinkIframe from "../../components/ManageLinkIframe";
import { ManageSchedular } from "../../components/ManageSchedular";

const Widget = () => {
  const { type } = useParams();
  if (type.toLowerCase() == "idrb") {
    return <ManageInvoices></ManageInvoices>;
  } else if (type.toLowerCase() == "link") {
    return <ManageLinkIframe></ManageLinkIframe>;
  } else if (type.toLowerCase() == "m365") {
    return <ManageSharePointReport></ManageSharePointReport>;
  } else if (type.toLowerCase() == "opsgenie") {
    return <ManageSchedular></ManageSchedular>;
  }
};

export default Widget;
