import React ,{ useState }from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { SubMenu } from '../components/SubMenu';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenu } from '../redux/userSlice';
import { Icon } from '@fluentui/react';

import logo from '../assets/logo.png';

import ProfilePopup from './ProfilePopup';
import styled from 'styled-components';
import { useGroupsAPI } from '../hooks/useGroupsAPI';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */

const LayoutWrapper = styled.div`
  disply: flex;
`;

const ContentWrapper = styled.div`
  width: ${({ isOpen }) => (isOpen ? 'calc(100% - 300px)' : '100%')};
  height: calc(100vh - 64px);
  padding: 5px 5px 0 5px;
  margin-top: 64px;

  transition: 0.6s ease-in-out all;
  margin-left: ${({ isOpen }) => (isOpen ? '300px' : '0')};
`;
// overflow-y: scroll; removed in above line if we need we can put again
export const PageLayout = (props) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { data,isLoading } = useGroupsAPI();

  const { isOpenMenu } = useSelector((store) => store.userReducer);

  const handleToggle = () => dispatch(toggleMenu());

  return (
    <>
      <Navbar
        style={{
          backgroundColor: '#4F5291',
          color: 'white',
          zIndex: 13,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: 64
        }}
      >
        <Nav className="container-fluid">
          <Nav.Item
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Navbar.Brand
              href="/"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src={logo}
                width="60"
                style={{ margin: '0 20px' }}
                height="30"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
              <span style={{ fontSize: 14, color: 'white' }}>
                RFA | Interactive Reports
              </span>
            </Navbar.Brand>
            <Icon
              onClick={location.pathname.includes("invoicepayment") || location.pathname.includes("paymentsuccess")?()=>{}:handleToggle}
              
              iconName={!isOpenMenu || location.pathname.includes("invoicepayment")  || location.pathname.includes("paymentsuccess")? 'GlobalNavButton' : 'Cancel'}
              style={{
                color: '#fff',
                marginLeft: 20,
                cursor: 'pointer'
              }}
            />
          </Nav.Item>
          <Nav.Item className="ml-auto">
            <Nav.Link eventKey={2} href="#">
              <ProfilePopup />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
      <LayoutWrapper>
        <SubMenu isOpen={(location.pathname.includes("invoicepayment")  || location.pathname.includes("paymentsuccess")) ?false :isOpenMenu} data={data} search={search} setSearch={setSearch} isLoading={isLoading}/>
        <ContentWrapper isOpen={location.pathname.includes("invoicepayment")  || location.pathname.includes("paymentsuccess")?false:isOpenMenu}>{props.children}</ContentWrapper>
      </LayoutWrapper>
    </>
  );
};
