import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Loader from '../../components/Loader';
import { useReportAPI } from '../../hooks/useReportAPI';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Global from '../Global';
import ManageInvoices from '../../components/ManageInvoices';
import ManageLinkIframe from '../../components/ManageLinkIframe';
import ManageSharePointReport from "../../components/ManageSharePointReports";

const Report = () => {
  const { id, type } = useParams();
  const [showDomo, setShowDomo] = useState(true)
  const { isLoading, emebedDetails } = useReportAPI({
    id, type,enable:type.toLowerCase() == "idrb" || type.toLowerCase() == "m365" || type.toLowerCase() == "link"
  });

  useEffect(() => {
      setShowDomo(false)
   
    setTimeout(() => {
      setShowDomo(true)
    }, 0);
  }, [id, type])


  if (isLoading) return <Loader />;

  if (type.toLowerCase() == "pbi") {
    return (
      <PowerBIEmbed
      key={id}
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          embedUrl: emebedDetails?.EmbedReport?.[0]?.EmbedUrl,
          accessToken: emebedDetails?.EmbedToken?.Token,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: true,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent
          }
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function () {
                console.log('Report loaded');
              }
            ],
            [
              'rendered',
              function () {
                console.log('Report rendered');
              }
            ],
            [
              'error',
              function (event) {
                console.log(event.detail);
              }
            ]
          ])
        }
        cssClassName={'report-style-class'}
      />
    );
  }
  else if(type.toLowerCase() == "idrb"){
    return <ManageInvoices></ManageInvoices>
  }
  else if(type.toLowerCase() == "link"){
  return   <ManageLinkIframe></ManageLinkIframe>
  }
  else if (type.toLowerCase() == "m365") {
    return <ManageSharePointReport></ManageSharePointReport>
  } else {
    return (
      showDomo ? <Global data={emebedDetails}  showDomo = {showDomo}/> : null

    )
  }


};

export default Report;
