
import { Controller } from "react-hook-form";
import * as React from "react";
import { SpinButton } from "@fluentui/react";

export const ControlledSpinButton = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({
        field: {  name: fieldName },
      }) => (
        <>
          <SpinButton
            {...props}
            onChange={props.onChange}
            name={fieldName}
            defaultValue={1}
            value={props.value}
            min={0}

          />
        </>
      )}
    />
  );
};
