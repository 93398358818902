import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CreateReport from "../../components/CreateReport";
import useManageReportAPI from "../../hooks/useManageReportAPI";
import { useManageReportsAPI } from "../../hooks/useManageReportsAPI";
import { ManageReportsComp } from "../Content/ManageReportsComp.js";
import UserFilterModal from "../../components/ManageModals/UserFilterModal.jsx"

const CONTROL = {
  GroupLookupId: "",
  company: "",
  ReportType: "",
};

const SEARCH_DATA = {
  GroupLookupId: "",
  company: "",
  ReportType: "",
};

const ManageReports = () => {
  const { reportId } = useParams();
  const { control, reset, setValue } = useForm();
  const [reportRecord, setReportRecord] = React.useState({});
  const { createMutation, updateMutation } =
    useManageReportAPI({
      id: reportRecord.id,
    });

  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState([]);

  const [searchData, setSearchData] = React.useState({ ...SEARCH_DATA });
  const [controls, setControl] = useState({ ...CONTROL });

  const handlecChangeDropdown = (name, value) => {
    const copyControl = { ...controls };

    copyControl[name] = value;
    setControl((prev) => ({ ...prev, ...copyControl }));
  };

  const onSearch = () => {
    setPage(1);
    setSearchData({
      GroupLookupId: controls["GroupLookupId"],
      company: controls["company"],
    });
  };

  const {
    data: list,
    isLoadingList,
    changedCount,
    actionDisabled,
    refetchList,
    handleChangeValue,
    handleDeleteReports,
    showModal,
    hideModal,
  } = useManageReportsAPI({
    page,
    enabled: true,
    sort,
    ...searchData,
  });

  const handleReset = () => {
    setControl({ ...CONTROL });
    setSearchData({ ...SEARCH_DATA });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const handleToggle = (isCreate) => {
    if (isCreate) setReportRecord({ ...{} });
    setOpenModal((prev) => !prev);
  };

  const handleCreate = () => {
    refetchList();
    toast.success(
      reportRecord.id
        ? "Widget updated successfully"
        : "Widget created successfully"
    );
  };
  const onEditClick = (record) => {
    setReportRecord({ ...record.original });
    setOpenModal(true);
  };


  return (
    <>
    
    <div style={{ display: "flex", alignContent: "center" }}>
      <h5 style={{ marginLeft: 8 }}>Manage Widgets</h5>
    </div>
    
      {/* <ToastContainer /> */}
      {openModal && (
        <CreateReport
          key={reportId}
          control={control}
          controls={controls}
          open={openModal}
          handleClose={handleToggle}
          createMutation={createMutation}
          updateMutation={updateMutation}
          onSuccess={handleCreate}
          reportId={reportId}
          reportRecord={reportRecord}
        />
      )}
{showFilter && (
              <UserFilterModal
              isModalOpen={showFilter}
              hideModal={()=>setShowFilter(false)}
              handlecChangeDropdown={handlecChangeDropdown}
              onSearch={onSearch}
              clear={handleReset}
              control={control}
              controls={controls}
              type={"widgets"}
            ></UserFilterModal>
      )}
      <ManageReportsComp
        reportId={reportId}
        list={list}
        isLoadingList={isLoadingList}
        createMutation={createMutation}
        changedCount={changedCount}
        refetchList={refetchList}
        handleToggle={handleToggle}
        page={page}
        setPage={setPage}
        control={control}
        controls={controls}
        reset={reset}
        setValue={setValue}
        handleChangeValue={handleChangeValue}
        actionDisabled={actionDisabled}
        handleDeleteReports={handleDeleteReports}
        handlecChangeDropdown={handlecChangeDropdown}
        clear={handleReset}
        handleCreate={handleCreate}
        isModalOpen={openModal}
        showModal={showModal}
        hideModal={hideModal}
        sort={sort}
        setSort={setSort}
        onSearch={onSearch}
        onEditClick={onEditClick}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

export default ManageReports;
