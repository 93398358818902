import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  Icon,
  TextField,
} from "@fluentui/react";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ControlledDropdown } from "../ControlledDropdown";
import { useCompaniesAPI } from "../../hooks/useCompaniesAPI";
import { useCompanyGroupsAPI } from "../../hooks/useCompanyGroupsAPI";
const UserFilterModal = ({
  isModalOpen,
  hideModal,
  control,
  controls,
  handlecChangeDropdown,
  onSearch,
  clear,
  type,
}) => {
  const [companyId, setCompanyId] = React.useState(controls.company);
  const SEARCH_GROUP_DATA = {
    companyId: companyId,
  };
  const [searchGroupData, setSearchGroupData] = React.useState({
    ...SEARCH_GROUP_DATA,
  });
  const { data: list } = useCompaniesAPI({
    page: 1,
    enabled: true,
    sort: null,
    searchData: {},
  });
  const { data: groupsList } = useCompanyGroupsAPI({
    page: 1,
    enabled: companyId&& companyId!="" ||(controls.company&& controls.company!=""),
    sort: null,
    filter: { ...searchGroupData },
  });
  const getCompanyListLu = () => {
    return list?.map((item) => {
      return { ...{ key: item.groupId, text: item.title } };
    });
  };
  const getGroupListLu = () => {
    return groupsList?.map((item) => {
      return { ...{ key: item.id, text: item.title } };
    });
  };
  const titleId = useId("title");
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{type ? "Filter Widgets" : "Filter Users"}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className={contentStyles.body}>
        <ControlledDropdown
          key={"Company" + open}
          // required={true}
          isComboBox
          label="Company"
          name="Company"
          control={control}
          onChange={(e, val) => {
            handlecChangeDropdown("company", val?.key);
            searchGroupData.companyId = val?.key;
            setSearchGroupData({ ...searchGroupData });
            setCompanyId(val?.key);
          }}
          options={getCompanyListLu()}
          rules={{ required: "This field is required" }}
          selectedKey={controls.company}
        />
        {type && <ControlledDropdown
          key={"GroupLookupId" + open}
          // required={true}
          label="Group"
          isComboBox
          name="GroupLookupId"
          control={control}
          onChange={(e, val) =>
            handlecChangeDropdown("GroupLookupId", val?.key)
          }
          options={getGroupListLu()}
          rules={{ required: "This field is required" }}
          selectedKey={controls.GroupLookupId}
        />}
        {!type &&<TextField
         key={"UserEmail" + open}
          label="Email"
          name="UserEmail"
          control={control}
          rules={{ required: "This field is required" }}
          value={controls.UserEmail}
          onChange={(e, val) => {
            handlecChangeDropdown("UserEmail", val);
          }}
        />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <PrimaryButton onClick={() => clear()}>
            <Icon iconName="Delete" />
            &nbsp;Clear Filter
          </PrimaryButton>
          <PrimaryButton onClick={() => onSearch()}>
            <Icon iconName="Search" />
            &nbsp;Apply Filter
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

const cancelIcon = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxWidth: "400px",
    minWidth: 400,
  },
  header: [
    {
      flex: "1 1 auto",
      borderTop: `4px solid rgb(79, 82, 145)`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default UserFilterModal;
