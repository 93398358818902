import * as React from "react";
import { useId } from "@fluentui/react-hooks";
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  Icon,
  DatePicker,
  TextField,
} from "@fluentui/react";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ControlledDropdown } from "../ControlledDropdown";
const InvoiceFilterModal = ({
  isModalOpen,
  hideModal,
  control,
  controls,
  handlecChangeDropdown,
  onSearch,
  clear,
}) => {
  const SEARCH_GROUP_DATA = {};
  const [searchGroupData, setSearchGroupData] = React.useState({
    ...SEARCH_GROUP_DATA,
  });
  const titleId = useId("title");
  return (
    <Modal
      titleAriaId={titleId}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <span id={titleId}>{"Filter Invoices"}</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          ariaLabel="Close popup modal"
          onClick={hideModal}
        />
      </div>
      <div className={contentStyles.body}>
        <DatePicker
          key={"FromDate" + open}
          required={true}
          label="From Date"
          name="FromDate"
          control={control}
          onSelectDate={(e) => {
            handlecChangeDropdown("fromDate", e);
            searchGroupData.fromDate = e;
            setSearchGroupData({ ...searchGroupData });
          }}
          rules={{ required: "This field is required" }}
          value={controls?.fromDate}
        />
        <DatePicker
          key={"ToDate" + open}
          required={true}
          label="To Date"
          name="ToDate"
          control={control}
          onSelectDate={(e) => {
            handlecChangeDropdown("toDate", e);
            searchGroupData.toDate = e;
            setSearchGroupData({ ...searchGroupData });
          }}
          rules={{ required: "This field is required" }}
          value={controls?.toDate}
        />
        <ControlledDropdown
          key={"Type" + open}
          label="Type"
          name="Type"
          control={control}
          onChange={(e, val) => {
            handlecChangeDropdown("type", val?.key);
            searchGroupData.type = val?.key;
            setSearchGroupData({ ...searchGroupData });
          }}
          options={[
            { key: "", text: "All" },
            { key: "Agreement", text: "Agreement" },
            { key: "Standard", text: "Standard" },
            { key: "Miscellaneous", text: "Miscellaneous" },
          ]}
          selectedKey={controls?.type}
        />
        <TextField
          required={true}
          label="Balance From"
          name="fromAmount"
          control={control}
          rules={{ required: "This field is required" }}
          value={controls?.fromAmount}
          type="number"
          onChange={(e, val) => {
            handlecChangeDropdown("fromAmount", e.target.value);
            searchGroupData.fromAmount = val;
            setSearchGroupData({ ...searchGroupData });
          }}
        />
        <TextField
          key={"toAmount" + open}
          required={true}
          label="Balance To"
          name="toAmount"
          control={control}
          onChange={(e, val) => {
            handlecChangeDropdown("toAmount", e.target.value);
            searchGroupData.toAmount = val;
            setSearchGroupData({ ...searchGroupData });
          }}
          rules={{ required: "This field is required" }}
          value={controls?.toAmount}
          type="number"
        ></TextField>
                <ControlledDropdown
          key={"Status" + open}
          label="Status"
          name="Status"
          control={control}
          onChange={(e, val) => {
            handlecChangeDropdown("status", val?.key);
            searchGroupData.status = val?.key;
            setSearchGroupData({ ...searchGroupData });
          }}
          options={[
            { key: "", text: "All" },
            { key: "Outstanding Only", text: "Outstanding Only" },
            { key: "Disputed Only", text: "Disputed Only" },
          ]}
          selectedKey={controls?.status}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "50px",
          }}
        >
          <PrimaryButton onClick={() => clear()}>
            <Icon iconName="Delete" />
            &nbsp;Clear Filter
          </PrimaryButton>
          <PrimaryButton onClick={() => onSearch()}>
            <Icon iconName="Search" />
            &nbsp;Apply Filter
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

const cancelIcon = { iconName: "Cancel" };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
    maxWidth: "400px",
    minWidth: 400,
  },
  header: [
    {
      flex: "1 1 auto",
      borderTop: `4px solid rgb(79, 82, 145)`,
      color: theme.palette.neutralPrimary,
      display: "flex",
      alignItems: "center",
      fontWeight: FontWeights.semibold,
      padding: "12px 12px 14px 24px",
    },
  ],
  body: {
    flex: "4 4 auto",
    padding: "0 24px 24px 24px",
    overflowY: "hidden",
    selectors: {
      p: { margin: "14px 0" },
      "p:first-child": { marginTop: 0 },
      "p:last-child": { marginBottom: 0 },
    },
  },
});
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default InvoiceFilterModal;
