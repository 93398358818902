import { useMemo } from "react";
import { customAPI } from "../services/api";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const getDisputeItems = (token, id) =>
  customAPI(token)
    .get(`api/Reports/${id}/InvoiceDisputeTotals`)
    .then((res) => {
      return {
        totalDispute: res?.data?.totalDispute,
        disputedItemsCount: res?.data?.disputedItemsCount,
        balance: res?.data?.balance,
        undisputedBalance: res?.data?.undisputedBalance,
        ccy:res?.data?.ccy,
        disputeObj:res.data
      };
    });

export const useInvoiceDisputeItems = ({ id }) => {
  const { token } = useSelector((store) => store.auth);
  let filter = ``;

  if (filter.toString().indexOf(" and ") == 0)
    filter = filter.replace(" and ", "");
  const { data, isLoading, isFetching } = useQuery([`Dispute`,id], () =>
    getDisputeItems(token, id)
  );

  const totalDisputeAmount = useMemo(() => data?.totalDispute, [data]);
  const totalLineItems = useMemo(() => data?.disputedItemsCount, [data]);
  const balance = useMemo(() => data?.balance, [data]);
  const undisputedBalance = useMemo(() => data?.undisputedBalance, [data]);
  const ccy = useMemo(() => data?.ccy, [data]);
  const disputeObj = useMemo(() => data?.disputeObj, [data]);
  return {
    totalDispute: totalDisputeAmount,
    totalLineItems: totalLineItems,
    isLoading: isFetching || isLoading,
    undisputedBalance: undisputedBalance,
    balance: balance,
    ccy: ccy,
    disputeObj:disputeObj
  };
};
